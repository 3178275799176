@import '../../../styles/helpers/variables.scss';

.edit {
  margin-bottom: -32px;
}

.top {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 32px;
  margin-left: -32px;
  margin-right: -32px;
  border-bottom: 1px solid $color-border-dark-30;
}

.buttonBack {
  width: 33px;
  height: 33px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover svg {
    fill: $color-grey-light;
    transition: all 0.2s ease-in;
  }
}

.title {
  min-height: 54px;
  width: 100%;
}
.options {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.templates {
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    color: #263b53;
  }
}
.select {
  width: 200px;
  & > div {
    background-color: #f7a860;
    color: #fff;
  }
}
.tourDate {
  color: $color-grey-title-75;
  margin: 0;
}

.loader {
  margin: 100px auto;
  width: 100%;
}

.tourName {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: $color-black-title;
  background-color: transparent;
  border: none;
  outline: none;
  width: inherit;
  margin-bottom: 8px;

  &::placeholder {
    color: $color-grey-title-75;
  }
}
