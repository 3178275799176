@import '../../../../../styles/helpers/variables.scss';

.tourSection {
  margin-bottom: 30px;
}

.section {
  margin-bottom: 32px;
}

.tour {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.tourName {
  max-width: 400px;
  position: relative;
  z-index: 10;
}

.tourDate {
  max-width: 326px;
  width: 100%;
  padding-top: 0;

  & label {
    font-size: 15px;
    color: #737b87;
    margin-bottom: 10px;
    font-family: Museo Sans Cyrl;
    padding-top: 4px;
  }

  & div {
    height: 40px;

    & span {
      margin-left: -6px;
      margin-top: 10px;
    }
  }
}

.tourDateIndividual {
  padding-top: 0;
  width: 330px;
  margin-right: 16px;
}

.adult {
  width: 136.5px;
}
.tourOperator {
  max-width: max-content;
  min-width: 245px;
}
.child {
  width: 136.5px;
}

.operator {
  width: 180px;
}
.systemOrder {
  width: 185px;
}
.checkboxes {
  width: 100%;
  display: flex;
  max-width: 1042px;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
}

.contracts {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 36px;
  padding: 16px 0 16px;
  & div {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.accordiontSection {
  max-width: 900px;
  border-bottom: 2px solid rgba(200, 205, 216, 0.3);
  margin-bottom: 24px;
  font-family: 'Museo Sans Cyrl';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #263b53;
}

.accommodation {
  margin-bottom: 35px;
}

.subTitle {
  color: $color-grey-title;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 18px;
}

.desc {
  max-width: 1229px;
  min-height: 100px;
  margin-bottom: 32px;

  :global {
    .ql-container {
      height: 83px;
    }

    .ql-editor {
      height: 83px;
      min-height: 83px;
    }
  }
}
.loader {
  margin-top: auto;
  margin-bottom: 20px;
}

.date_departure {
  min-width: 900px;
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.departure_input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 260px;
  & label {
    font-size: 15px;
    color: #737b87;
    font-family: Museo Sans Cyrl;
    font-weight: 400;
  }
  & input {
    background: #f5f6fa;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 10px 16px;
    width: 100%;
    line-height: 18px;
    height: 38px;
    color: #263b53;
    font-size: 15px;
    font-weight: 500;
  }
}

.type_departure {
  max-width: 200px;
  padding: 0;
  & label {
    font-size: 15px;
    color: #737b87;
    font-family: Museo Sans Cyrl, sans-serif !important;
    font-weight: 400;
  }
}
