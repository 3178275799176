@import '../helpers/variables';

html,
body {
  padding: 0;
  margin: 0;
  color: $color-black-font;
  background-color: $color-white-light;
  font-family: Museo Sans Cyrl, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}

h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: $color-black-title;
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: $color-black-title;
}

h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: $color-black-title;
}

.container {
  max-width: 1440px;
  min-width: 1440px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-right: 32px;
  // outline: 1px solid blue;
  @media (min-width: 1600px) {
    max-width: 100%;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
