@import '../../../../../../styles/helpers/variables.scss';

.groupParams {
  display: flex;
  flex-direction: column;
  max-width: 1108px;
  padding-bottom: 16px;
  border-bottom: 2px solid $color-border-dark-30;
}
.parameters {
  display: flex;
  gap: 16px;
  width: 100%;
}

.input {
  width: 189px;
  & div {
    background: $color-table-bg;
    border-radius: 5px;
    border: none;
  }
  & input {
    background: $color-table-bg;
  }
}

.calendar {
  width: 189px;
  & input {
    height: 38px;
    background: $color-table-bg;
    border-radius: 5px;
    border: none;
  }
}

.calendarError input {
  outline: 1px solid $color-red;
}

.priceOneDay {
  width: 260px;
}
.selector {
  width: 189px;
}
.sales {
  width: 100px;
}

.selectorError div {
  outline: 1px solid $color-red;
}

.addTrip {
  margin: 15px 0;
  background-color: $color-orange;

  &:hover {
    background-color: $color-orange-hover;
  }
}

.number {
  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
  & input[type='number']:hover,
  & input[type='number']:focus {
    -moz-appearance: number-input;
  }

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.fixedContainer {
  width: 100%;
  position: fixed;
  left: 19%;
  bottom: 0;
  background: white;
  padding: 10px;
  & > button {
    margin-left: 15px;
  }
}
