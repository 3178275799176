@import '../../../../../styles/helpers/variables.scss';

.selectors {
  position: relative;
  display: flex;
  // align-items: center;
  gap: 16px;
  border-bottom: 2px solid $color-border-dark-30;
  padding-bottom: 32px;
}
.form {
  display: flex;
  flex-direction: column;
}
.selector {
  max-width: max-content;
  min-width: 169px;
}
.currencies {
  min-width: 95px;
}
.selectorError div {
  outline: 1px solid red;
}

.descriptions {
  padding-top: 32px;
}

.subTitle {
  color: $color-grey-title;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 18px;
}

.desc {
  min-height: 100px;
  margin-bottom: 32px;

  :global {
    .ql-container {
      height: 83px;
    }

    .ql-editor {
      height: 83px;
      min-height: 83px;
    }
  }
}
.shortDesc {
  min-height: 110px;
  margin-bottom: 32px;

  :global {
    .ql-container {
      height: 110px;
    }

    .ql-editor {
      height: 110px;
      min-height: 110px;
    }
  }
}
.fullDesc {
  min-height: 500px;
  margin-bottom: 32px;

  :global {
    .ql-container {
      height: 500px;
    }

    .ql-editor {
      height: 500px;
      min-height: 500px;
    }
  }
}
.mtmla {
  margin-top: 16px;
  margin-left: auto;
}
.mr {
  margin-top: 0;
  margin-right: 66px;
}
.tourProgram {
  margin-bottom: 32px;
}

.duration {
  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }

  & input[type='number']:hover,
  & input[type='number']:focus {
    -moz-appearance: number-input;
  }

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.system_name {
  width: 210px;
}
.discount {
  width: 85px;
}

.commision {
  width: 99px;
}
.duration {
  width: 189px;
}
.durationMin {
  width: 98px;
}
.durationBlock {
  width: max-content;
  max-width: 189px;
  position: relative;
}
.price {
  width: 100%;
}
.priceBlock {
  width: 110px;
  position: relative;
}
.helper {
  position: relative;
  // & > svg {
  //   position: absolute;
  //   top: -8px;
  //   right: -8px;
  // }
}
.helpBlock {
  width: max-content;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(91, 101, 114, 0.18);
  color: var(--unnamed, #263b53);
}
.priceHelp {
  position: absolute;
  top: -20px;
  right: -110px;
  max-width: 135px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.durationHelp,
.durationHelpMin {
  position: absolute;
  top: 0px;
  right: -200px;
  max-width: 220px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 99999;
}
.durationHelpMin {
  right: -130px;
}
.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.subtitle {
  color: $color-grey-title;
  font-size: 15px;
  line-height: 18px;
}

.titleProgram {
  width: 100%;
  max-width: 1110px;
  border-radius: 5px;
  border: 1px solid var(--unnamed, rgba(200, 205, 216, 0.6));
  background: #fff;
  height: 28px;
  padding: 10px 16px;
  &:hover {
    border: 1px solid $color-border-dark;
  }
  color: $color-black-title;

  &:focus {
    outline: none;
    border: 1px solid $color-border-dark;
  }
  & > div {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fullDesc {
  min-height: 500px;
  margin-bottom: 32px;

  :global {
    .ql-container {
      height: 500px;
    }

    .ql-editor {
      height: 500px;
      min-height: 500px;
    }
  }
}
.remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: none;
  background: transparent;
  cursor: pointer;

  & svg {
    fill: #c8cdd8;
  }

  &:hover svg {
    fill: tomato;
  }
}

.priority {
  max-width: 75px;
  margin-top: 20px;
  & > div {
    overflow: hidden;
    & input {
      color: #1886e9;
      text-align: center;
    }
    & input[type='number']::-webkit-outer-spin-button,
    & input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    & input[type='number'] {
      -moz-appearance: textfield;
    }

    & input[type='number']:hover,
    & input[type='number']:focus {
      -moz-appearance: number-input;
    }

    & input[type='number']::-webkit-inner-spin-button,
    & input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.columnGroup {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.applicationBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 82px;
  & > h2 {
    color: #263b53;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.addFileBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  & > label {
    cursor: pointer;
    display: flex;
    padding: 5px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
    border: 1px solid var(--neutral-5, #d9d9d9);
    background: var(--neutral-1, #fff);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}
.addFile {
  display: none;
}

.meta_tags {
  padding-top: 10px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid $color-border-dark-30;
  & p {
    color: #263b53;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & p {
    margin: 0;
    padding: 0;
  }
}

.textarea {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
}

.textareaLabel {
  font-size: 15px;
  line-height: 18px;
  color: $color-grey-title;
  margin-bottom: 10px;
}

.textareaText {
  height: 100%;
  min-height: 100px;
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.6);
  border-radius: 5px;
  padding: 10px 16px;
  resize: none;
  color: $color-black-title;
  &:focus {
    outline: none;
  }
}
