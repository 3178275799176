.top {
  padding: 32px 0 12px;
  width: 100%;
}

.orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.search {
  display: flex;
  gap: 16px;
}
.btn {
  margin-left: auto;
  height: 38px;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 20px;
  padding-bottom: 24px;
  border-bottom: 2px solid var(--unnamed, rgba(200, 205, 216, 0.3));
  margin-bottom: 20px;
  background: #eaecf3;
  border-radius: 24px;
  padding: 25px;
}
.formTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > h3 {
    color: #263b53;
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
  }
}
.hideBtn {
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  color: var(--unnamed, #70a4cb);
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
}

.formContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.loadedPhotoList {
  width: 100%;
  max-width: 786px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.files {
  width: 100%;
  max-width: 786px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  & > article {
    width: 100%;
    max-width: 480px;
    margin-bottom: 0;
  }
}

.formsPhoto {
  width: 100%;
  max-width: 786px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.photoForm {
  width: 100%;
  display: flex;
  max-width: 786px;
  padding: 10px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: var(--unnamed, #f5f6fa);
}

.photoFormImage {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 150px;
  gap: 5px;
  & > img {
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 5px;
  }
  & > h2 {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & > span {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.photoFormInputs {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  & > label {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & > input {
    width: 100%;
    padding: 10px 16px;
    border-radius: 5px;
    border: 1px solid var(--unnamed, rgba(200, 205, 216, 0.6));
    background: #fff;
    color: var(--unnamed, #263b53);
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
  }
}
.remove {
  background: none;
  border: none;
  cursor: pointer;
  margin: auto 0;
}
.select {
  width: 100%;
  max-width: 728px;
  & > div {
    background: #fff;
    & > div {
      background: #fff;
      & > div {
        background: #fff;
        & > div {
          & > div {
            display: flex;
            padding: 5px 10px;
            align-items: center;
            gap: 16px;
            border-radius: 8px;
            background: var(--unnamed, #f5f6fa);
          }
        }
      }
    }
  }
}
