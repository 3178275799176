@import '../../../../styles/helpers/variables.scss';

.header {
  width: 100%;
  max-width: 1440px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 13px;
  position: fixed;
  z-index: 2;
  background-color: $color-white;
  padding-right: 32px;
  @media (max-width: 360px) {
    padding-right: 0;
  }
}

.user {
  position: relative;
}

.menu {
  position: absolute;
  content: '';
  width: 315px;
  top: 50px;
  right: 0;
  background: $color-white;
  box-shadow: 1px 4px 10px rgba(91, 101, 114, 0.18);
  border-radius: 8px;
  padding: 24px 32px;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 16px;

  & button:last-child {
    margin-top: 0;
  }
}
