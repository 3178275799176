@import '../../../styles/helpers/variables.scss';

.tableWrapper {
  overflow: hidden;
  width: 100%;
  max-width: inherit;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_id {
    min-width: 72px;
    border-radius: 8px 0px 0px 0px;
    cursor: pointer;
  }

  &_name {
    min-width: 28.125rem;
    @media (max-width: $desktop-width) {
      min-width: 16rem;
    }
  }

  &_type {
    min-width: 20.375rem;
    @media (max-width: $desktop-width) {
      min-width: 12rem;
    }
  }

  &_date {
    min-width: 12.5rem;
  }

  &_author {
    min-width: 11.875rem;
    width: 100%;
    border-radius: 0px 8px 0px 0px;
    @media (max-width: $desktop-width) {
      min-width: 0px;
    }
  }
}

.cell {
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 14px 16px;
  font-size: 16px;
  height: 73px;
}

.rows,
.row {
  display: flex;
}

.count {
  padding: 14px 16px;
  color: $color-grey-title-75;
  font-size: 15px;
  line-height: 18px;
  border: none;
  height: 48px;
}

.notFound {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}

.loader {
  margin: 20px auto;
  width: 100%;
}

.user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.author {
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.name {
  color: $blue-color-dark;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  text-decoration: none;
  word-break: break-all;
}
