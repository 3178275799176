@import '../../../../styles/helpers/variables.scss';

.table {
  display: flex;
  flex-direction: column;
  color: $color-black-title;
  padding-top: 8px;
}

.header {
  display: flex;
  border-radius: 8px 8px 0px 0px;
  background: $color-table-bg;

  border: 1px solid rgba(200, 205, 216, 0.3);
}

.column {
  padding: 12px 16px;

  &_1 {
    width: 125px;
  }

  &_2 {
    width: 433px;
  }

  &_3 {
    width: 186px;
  }
}

.row {
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-top: none;
  display: flex;
  background-color: $color-white;
  cursor: pointer;

  &:hover {
    background-color: $color-table-bg;
  }
}

.cell {
  padding: 14px 16px;
  display: flex;
  align-items: center;
}

.date {
  width: 125px;
}

.tour {
  width: 433px;
}

.status {
  width: 186px;
}
