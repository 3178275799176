@import '../../../../../../styles/helpers/variables.scss';

.tableWrapper {
  margin-bottom: 16px;
}

.title {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: $color-black-title;
  font-family: 'Museo Sans Cyrl';
  margin-bottom: 16px;
}

.table {
  width: 690px;
  background: $color-white;
  border: none;
  border-radius: 8px 8px 0px 0px;
  margin-top: 12px;
  border-collapse: collapse;
  margin-right: 24px;
  border-bottom: 2px solid $color-border-dark-30;
}

.column {
  color: $color-black-title;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  height: 50px;
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;

  &_id {
    width: 40px;
    border-radius: 8px 0px 0px 0px;
  }

  &_type {
    padding-left: 0;
    min-width: 156px;
  }

  &_date {
    padding-left: 0;
    min-width: 100px;
  }

  &_sum {
    padding-left: 0;
    min-width: 75px;
  }
  &_rate {
    min-width: 88px;
  }

  &_rub {
    min-width: 110px;
  }
}

.cell {
  color: $color-black-title;
  padding: 9px 12px;
  font-size: 16px;
  min-height: 38px;
  background-color: #f5f6fa;
  border-radius: 8px;
}

.headers {
  display: flex;
  background-color: $color-table-bg;
  border-radius: 8px;
}

.row {
  display: flex;
  align-items: center;
}

.rowView {
  padding-top: 16px;
}

.id {
  min-width: 40px;
  margin-right: 16px;
  min-height: 38px;
  background-color: transparent;
}

.type {
  min-width: 146px;
  max-width: 146px;
  margin-right: 16px;

  & div {
    max-height: none !important;
    min-width: 146px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.date {
  min-width: 120px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;

  & div {
    & input {
      min-width: 110px;
      padding-left: 10px !important;
      background-color: #f5f6fa;
      border: none;
      min-height: 38px;
    }
  }
}

.sum {
  min-width: 91px;
  max-width: 91px;
  margin-right: 16px;
}

.sumView {
  min-width: 91px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  border: 1px solid #c8cdd8;
  background: #f5f6fa;
  overflow: hidden;
  padding: 0;
  & > div {
    border-right: 1px solid #c8cdd8;
    background: #f5f6fa;
    height: 100%;
    padding: 10px;
    margin-right: 5px;
    color: black;
  }
}

.newItem {
  border: none;

  &:active {
    border: none;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}

.delete {
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;

  & svg {
    fill: #a4a4a4;
    &:hover {
      fill: #ff5144;
    }
  }
}

.resultWrapper {
  width: 695px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -35px;
}

.resultItem {
  display: flex;
  align-items: center;
  // padding: 10px 16px;
  background: #f5f6fa;
  border-radius: 5px;
  width: 376px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: $color-black-title;
  border: 1px solid #c8cdd8;
  background: #f5f6fa;
  overflow: hidden;
  & > div {
    border-right: 1px solid #c8cdd8;
    background: #f5f6fa;
    height: 100%;
    padding: 10px;
    margin-right: 5px;
    color: black;
  }
  & > span:last-child {
    margin-left: auto;
    padding-right: 16px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.sumNumber {
  color: $color-grey-title-75;
}
