@import '../../../../styles/helpers/variables.scss';

.group {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.input {
  width: 100%;
}

.numberDoc {
  width: 100%;
  min-width: 520px;
}

.number {
  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
  & input[type='number']:hover,
  & input[type='number']:focus {
    -moz-appearance: number-input;
  }

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
