@import '../../styles/helpers/variables.scss';

.reminders {
  padding: 16px 0;
}

.title {
  margin-bottom: 16px;
}
.disabled {
  background: #f1f3f5;
  opacity: 0.6;
}
.addNewReminder {
  display: flex;
  background-color: $color-table-bg;
  border-radius: 4px;
  position: relative;
  align-items: center;
  justify-content: space-between;

  &::before {
    position: absolute;
    content: '';
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 19px;
    height: 19px;
    background-color: transparent;
    border-radius: 50%;
    border: 1.5px solid $color-border-dark;
  }
}

.labelNewReminder {
  width: 550px;
  padding-left: 51px;
  border: none;
  background-color: $color-table-bg;

  &::placeholder {
    color: $color-grey-title-75;
  }

  &:focus {
    outline: none;
  }
}

.btnNewReminder {
  border: none;
  padding: 10px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
