@import '../../styles/helpers/variables.scss';

.select {
  width: 100%;
  padding: 16px 0 16px;
  position: relative;
}

.label {
  font-size: 15px;
  color: $color-grey-label;
  margin-bottom: 10px;
  display: block;
}

.header {
  background: #f5f6fa;
  border-radius: 8px 8px 8px 8px;
  min-height: 38px;
  padding: 8px 16px;
  color: $color-black-title;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;

  &::after {
    position: absolute;
    width: 9px;
    height: 5px;
    content: '';
    right: 16px;
    top: 50%;
    background-image: url('../../assets/icons/downContur.svg');
    background-position: 50% 50%;
    background-size: 9px 5px;
  }
}

.headerOpened {
  border-radius: 8px 8px 0px 0px;

  &::after {
    transform: rotate(180deg);
  }
}

.list {
  box-shadow: 0px 4px 8px rgba(91, 101, 114, 0.25);
  border-radius: 0px 0px 8px 8px;
  z-index: 1;
  position: absolute;
  content: '';
  width: inherit;
}

.item {
  background: $color-white;
  padding: 8px 16px;
  color: $color-black-title;
  font-size: 15px;
  cursor: pointer;

  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  &:hover {
    background: #f5f6fa;
  }
}

.badge {
  height: 29px;
  background: $color-white;
  border-radius: 8px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  cursor: auto;
}

.close {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}
