@import '../../../styles/helpers/variables.scss';

.action {
  padding: 24px 0;
  border-bottom: 2px solid $color-border-dark-30;
  display: flex;
  gap: 16px;
}

.top {
  display: flex;
  gap: 24px;
  font-size: 14px;
  line-height: 17px;
  color: $color-grey-title;
  margin-bottom: 16px;
}

.manager {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 5px;
    right: -15px;
    background-color: $color-grey-title;
  }
}

.description {
  font-size: 15px;
}

.status {
  & span {
    font-weight: 600;
  }
}
