@import '../../../styles/helpers/variables.scss';

.create {
  width: 100%;
  padding-bottom: 1.25rem;
  overflow-x: hidden;

  & {
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.top {
  width: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px 10px;
  margin-bottom: 16px;
}

.title {
  display: inline-block;
}

.content {
  min-width: 1277px;
  padding: 0 24px;
}
