@import 'styles/helpers/variables';

.userBasic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userAdvanced {
  padding-right: 19px;
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    width: 11px;
    height: 6px;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-image: url('../../assets/icons/down.svg');
  }
}

.name {
  color: $color-black-title;
  margin-right: 8px;
}

.nameBasic {
  color: $color-black-title;
  margin-right: 0;
}

.imgWrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid $color-violet;
  background-color: $color-violet;
}
.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar {
  width: 32px;
  height: 32px;
  background-color: $color-violet;
  border-radius: 50%;
  font-size: 13px;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 8px;
}
