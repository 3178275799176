@import '../../styles/helpers/variables.scss';

.wrapper input[type='checkbox'] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  border: 0.888889px solid #c8cdd8;
  border-radius: 2.4127px;
  outline: none;
  cursor: pointer;
  & input[type='checkbox']:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }

  & input[type='checkbox']:disabled + span {
    color: #c0c0c0;
  }
}
.wrapper input[type='checkbox']:focus {
  box-shadow: 0 0 5px #b8bbbc;
}
.wrapper input[type='checkbox']:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.wrapper input[type='checkbox']:disabled + span {
  color: #c0c0c0;
}
.wrapper label {
  display: flex;
  align-items: center;
}
input.checked {
  background-color: #70cbbb;
  position: relative;
}

input.checked::before {
  content: '\2714';
  font-size: 14px;
  color: #fff;
  position: absolute;
  right: 1px;
  top: 0px;
}
