@import '../../../../../../styles/helpers/variables.scss';

.tableWrapper {
  margin-bottom: 16px;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_period {
    width: 185px;
    border-radius: 8px 0px 0px 0px;
  }
  &_night_count {
    width: 100px;
  }
  &_accommodation {
    width: 217px;
  }
  &_type {
    width: 140px;
  }
  &_category {
    width: 120px;
  }
  &_accom {
    width: 108px;
  }

  &_price {
    width: 90px;
  }
  &_price_curant {
    width: 128px;
  }
  &_tourists {
    width: 297px;
  }
}

.cell {
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 9px 12px;
  font-size: 16px;
  min-height: 38px;
}

.headers {
  display: flex;
  background-color: $color-table-bg;
  border-radius: 8px;
}

.row {
  display: flex;
  align-items: center;
}

.period {
  width: 175px;
}
.nightCount {
  width: 100px;
}
.hotel {
  width: 217px;
}
.type {
  width: 140px;
}
.category {
  width: 120px;
}
.accomodationType {
  width: 110px;
}
.price {
  width: 100px;
}
.comission {
  width: 115px;
}
.tourist {
  width: 290px;
}
.newItem {
  border: none;

  &:active {
    border: none;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}

.delete {
  cursor: pointer;
}
.btnRow {
  padding-top: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.saveRow {
  padding: 4px 25px;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  background: var(--unnamed, #70a4cb);
  box-shadow: 2px 4px 8px 0px #e5e8f3;
  color: #fff;
  text-align: center;
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

.addRow {
  padding: 4px 25px;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  box-shadow: 2px 4px 8px 0px #e5e8f3;
  color: var(--unnamed, #70a4cb);
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}
