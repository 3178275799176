@import '../../../styles/helpers/variables.scss';

.file {
  width: 344px;
  min-height: 60px;
  max-height: 120px;
  border: 1px solid $color-border-dark-60;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  padding: 10px 16px;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
}

.imgWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
}

.params {
  width: 295px;
  gap: 5px;
  display: flex;
  color: $color-grey-title;
}

.size {
  white-space: nowrap;
}

.type {
  overflow: hidden;
  white-space: nowrap;
}

.name {
  max-width: 295px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: $color-black-title;
}

.delete {
  position: absolute;
  content: '';
  top: 10px;
  right: 16px;
  width: 10px;
  height: 10px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download {
  background: none;
  position: absolute;
  content: '';
  bottom: 5px;
  right: 16px;
  width: 10px;
  height: 10px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
