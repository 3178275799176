@font-face {
  font-family: 'Museo Sans Cyrl';
  src: local('Museo Sans Cyrl'), url('../../assets/fonts/MuseoSansCyrl-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: local('Museo Sans Cyrl'), url('../../assets/fonts/MuseoSansCyrl-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: local('Museo Sans Cyrl'), url('../../assets/fonts/MuseoSansCyrl-700.woff2') format('woff2');
  font-weight: 700;
  font-style: bold;
}
