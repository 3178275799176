@import '../../styles/helpers/variables.scss';

.filter {
  position: relative;
}
.button {
  height: 38px;
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px;
  padding: 8px 16px;
  color: $color-grey-title-75;
  font-size: 15px;
  cursor: pointer;
}

.buttonActive {
  border: 1px solid #70a4cb;
  color: #70a4cb;
}

.close {
  width: 38px;
  height: 37px;
  border: none;
  background: transparent;
  padding: 0;
  margin: -8px -16px 0 16px;
  border-left: 1px solid #70a4cb;
  cursor: pointer;
}
