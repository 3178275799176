@import '../../styles/helpers/variables.scss';

.wrapperEditor {
  min-height: 178px;
}
.disabled {
  min-height: 178px;

  :global {
    .ql-container {
      height: 178px;
      border-radius: 20px;
      border: none;
      margin-bottom: 16px;
    }

    .ql-toolbar {
      border: none;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid $color-border-dark-30;
      background: #f1f3f5;
      opacity: 0.6;

      & .ql-bold,
      .ql-italic,
      .ql-underline,
      .ql-list,
      .ql-align,
      .ql-picker-label,
      .ql-link {
        & .ql-stroke {
          stroke: $color-border-dark;
        }
        & .ql-fill {
          stroke: $color-border-dark;
        }

        &:hover {
          & .ql-stroke {
            stroke: $blue-color;
          }
          & .ql-fill {
            stroke: $blue-color;
          }
        }
      }
    }

    .ql-editor {
      font-family: 'Museo Sans Cyrl';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-black-title;
      background: #f1f3f5;
      opacity: 0.6;
      border: none;
      border-radius: 0 0 5px 5px;
      min-height: 178px;
      max-height: 1240px;
      overflow-y: auto;

      &.ql-editor.ql-blank::before {
        color: $color-grey-title-75;
      }
    }
  }
}
.editor {
  min-height: 178px;

  :global {
    .ql-container {
      height: 178px;
      border-radius: 20px;
      border: none;
      margin-bottom: 16px;
    }

    .ql-toolbar {
      border: none;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid $color-border-dark-30;
      background-color: $color-table-bg;

      & .ql-bold,
      .ql-italic,
      .ql-underline,
      .ql-list,
      .ql-align,
      .ql-picker-label,
      .ql-link {
        & .ql-stroke {
          stroke: $color-border-dark;
        }
        & .ql-fill {
          stroke: $color-border-dark;
        }

        &:hover {
          & .ql-stroke {
            stroke: $blue-color;
          }
          & .ql-fill {
            stroke: $blue-color;
          }
        }
      }
    }

    .ql-editor {
      font-family: 'Museo Sans Cyrl';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-black-title;
      background-color: $color-table-bg;
      border: none;
      border-radius: 0 0 5px 5px;
      min-height: 178px;
      max-height: 1240px;
      overflow-y: auto;

      &.ql-editor.ql-blank::before {
        color: $color-grey-title-75;
      }
    }
  }
}
