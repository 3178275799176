@import '../../styles/helpers/variables.scss';

.block {
  display: flex;
  flex-direction: column;

  &:focus-within .inputWrapper {
    border: 1px solid $color-border-dark;
  }
}

.label {
  color: $color-grey-title;
  margin-bottom: 10px;
  font-size: 15px;
}

.inputWrapperDisabled {
  background: #f5f6fa !important;
  // opacity: 0.6 !important;
  // border: none !important;
  &:hover {
    border: 1px solid rgba(200, 205, 216, 0.6) !important;
  }
}
.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border: 1px solid rgba(200, 205, 216, 0.6);
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  line-height: 18px;
  height: 38px;
  .rateBlock {
    padding: 10px;
    background-color: #f5f6fa;
    border-right: 1px solid #c8cdd8;
    height: 100%;
    font-size: 15px;
    font-weight: 500;
    color: black;
  }
  &:hover {
    border: 1px solid $color-border-dark;
  }
}
.input {
  border: none !important;
  width: 100%;
  color: $color-black-title;

  &:focus {
    outline: none;
  }
}

.iconLeft {
  //   padding-left: 16px;
  padding-right: 16px;
}

.error {
  border: 1px solid $color-red !important;
}
