@import '../../styles/helpers/variables.scss';

.panel {
  height: 33px;
  border-bottom: 2px solid rgba(200, 205, 216, 0.3);
  margin: 0 -32px 24px;
}

.list {
  display: flex;
  padding: 0 32px;
}

.item {
  margin-right: 32px;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background-color: transparent;
  height: 33px;
  color: $color-grey-title-75;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #70cbbb;
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}

.buttonActive {
  color: $color-grey-title;
}
