.content {
  display: flex;
  justify-content: flex-start;
  padding-top: 60px;
}

.outletWrapper {
  display: flex;
  width: 100%;
}

.outlet {
  background-color: #f1f3f5;
  min-height: 100vh;
  width: 100%;
  max-width: inherit;
  padding: 0 32px 32px;
  margin-right: -32px;
}
