@import '../../../../../styles/helpers/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #263b53;
  margin-bottom: 24px;
}

.select {
  margin-bottom: 10px;
}

.table {
  border-radius: 8px;
  border: 1px solid rgba(200, 205, 216, 0.3);
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  }
}

.rowTitle {
  min-width: 321px;
  padding: 10px 12px;
  background: rgb(245, 246, 250);
  color: rgb(38, 59, 83);
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  border-right: 1px solid rgba(200, 205, 216, 0.3);
}

.rowBody {
  width: 100%;
  padding: 10px 12px;
  color: rgb(38, 59, 83);
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  border: none;
  outline: none;
}

.sumContainer {
  display: flex;
  border: 1px solid rgb(200, 205, 216);
  border-radius: 8px;
  margin-left: auto;
  overflow: hidden;
  & > div {
    padding: 10px 16px 10px 16px;
    background: rgb(245, 246, 250);
    border-right: 1px solid rgb(200, 205, 216);
    color: rgb(38, 59, 83);
    font-family: Museo Sans Cyrl;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0%;
  }
  & > input {
    border: none;
    outline: none;
    text-align: center;
    color: rgb(91, 101, 114);
    font-family: Museo Sans Cyrl;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0%;
  }
}
