@import '../../../../styles/helpers/variables.scss';

.form {
  display: flex;
  padding-bottom: 20px;
}

.navbar {
  width: max-content;
  min-width: 206px;
  background: $color-white;
  border-right: 1px solid $color-border-dark-30;
  margin-left: -32px;
  padding-top: 5px;
}

.navbarItem {
  height: 43px;
  color: $color-grey-title;
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border-bottom: 1px solid $color-border-dark-30;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: $color-table-bg;
    color: $blue-color-dark;
    transition: all 0.2s ease-in;
  }
}

.navbarItemActive {
  background-color: $color-table-bg;
  color: $blue-color-dark;
}

.navbarItemDate {
  border-bottom: none;
}

.navbarBtn {
  width: 20px;
  height: 20px;
  padding: 0;
  margin-left: 10px;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  & svg {
    stroke: $blue-color;

    &:hover {
      stroke: $blue-color-dark;
    }
  }
}

.content {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 24px 28px;
  margin-right: -32px;
  background: $color-white;
}

.trips {
  padding-top: 2px;
  overflow-y: auto;
  height: 400px;
  padding-bottom: 60px;
  &::-webkit-scrollbar {
    width: 4px; /* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: none; /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(118, 116, 116); /* цвет плашки */
    border-radius: 100px;
    border-right: none;
    border-left: none;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }
}

.trip {
  // width: 180px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
  padding: 8px 0 8px 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: $color-black-title;
  cursor: pointer;
  white-space: nowrap;
  & span {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  & .editTrip {
    display: flex;
  }
  &:hover {
    color: $blue-color-dark;
    background-color: $color-table-bg;
  }
  & > div {
    margin-left: auto;
  }
}

.tripActive {
  color: $blue-color-dark;
  background-color: $color-table-bg;

  & .editTrip {
    display: flex;
  }
}

.placeholderDate {
  color: $color-border-dark;
}

.editTrip {
  display: none;
  margin-right: 5px;
  margin-left: auto;
}

.editBlock {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  padding: 10px;
  & > button {
    background-color: $color-orange;

    &:hover {
      background-color: $color-orange-hover;
    }
  }
}

.deleteBtn {
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  padding: 8px 16px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  & svg {
    order: -1;
  }
}
