@import '../../../styles/helpers/variables.scss';

.create {
  // padding: 24px 32px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  & {
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 2px solid rgba(200, 205, 216, 0.3);
  padding: 24px 32px 24px;
  margin-bottom: 16px;
}

.content {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.status {
  font-size: 15px;
  color: $color-grey-title;
  margin-top: 6px;
}
