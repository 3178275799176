@import '../../../../styles/helpers/variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 200px;
}
.useInfoItem {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  & > p {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    & > span {
      color: var(--unnamed, #263b53);
    }
  }
  & > span {
    padding-top: 6px;
  }
}

.helpBlock {
  width: max-content;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(91, 101, 114, 0.18);
  color: var(--unnamed, #263b53);
}
.durationHelp {
  position: absolute;
  top: 35px;
  left: 275px;
  max-width: 220px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 99999;
}
.photoHelp {
  position: absolute;
  top: 35px;
  left: 145px;
  max-width: 220px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 99999;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.row {
  display: flex;
  gap: 40px;
}
.inputGroup {
  display: flex;
  width: 100%;
  &:first-child {
    max-width: 500px;
  }
  &:last-child {
    max-width: 674px;
  }
  align-items: flex-start;
  gap: 10px;
  & > div {
    width: 100%;
  }
}
.miltiSelect {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subtitle {
  color: #737b87;
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.photos {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.photosHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > h2 {
    color: #263b53;
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
  }
}

.hideBtn {
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  color: var(--unnamed, #70a4cb);
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
}
.listInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  & li {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.photoList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 390px);
  gap: 20px;
}
.photo {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--unnamed, #f5f6fa);
}

.imageContainer {
  position: relative;
  width: 100%;
  max-width: 150px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
  & > span {
    position: absolute;
    border-radius: 0px 6px 0px 0px;
    background: var(--unnamed, #7084cb);
    padding: 2px 8px;
    color: #fff;
    text-align: center;
    font-family: Museo Sans Cyrl;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    top: 0;
    right: 0;
  }
}

.photo_title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > h3 {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & > div > svg {
    margin-top: 2px;
  }
  & > div > p {
    margin: 0;
    color: var(--unnamed, rgba(91, 101, 114, 0.75));
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.remove {
  cursor: pointer;
}
