@import '../../../../../../styles/helpers/variables.scss';

.tableWrapper {
  margin-bottom: 16px;
}

.select {
  .mantine-Select-root {
    color: red;
    background-color: blanchedalmond;
  }
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    width: 237px;
    border-radius: 8px 0px 0px 0px;
  }

  &_price {
    width: 232px;
  }

  &_quantity {
    width: 160px;
  }
}

.cell {
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 9px 12px;
  font-size: 16px;
  min-height: 38px;
}

.headers {
  display: flex;
  background-color: $color-table-bg;
  border-radius: 8px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
}

.row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
}

.title {
  width: 231px;
}

.price {
  width: 241px;
}

.quantity {
  width: 160px;
}

.newItem {
  border: none;

  &:active {
    border: none;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}

.delete {
  cursor: pointer;
}
