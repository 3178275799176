@import '../../styles/helpers/variables.scss';

.signin {
  display: flex;
  height: 100vh;
  background-color: $color-frame-bg;
}

.bg {
  width: 50%;
  height: 100%;
  min-height: 821px;
  background: linear-gradient(
      180deg,
      #446683 5.82%,
      rgba(68, 102, 131, 0.89) 21.05%,
      rgba(68, 102, 131, 0.72) 35.47%,
      rgba(57, 85, 113, 0) 64%,
      rgba(42, 65, 89, 0.68) 90.34%,
      #263b53 100%
    ),
    url('../../assets/bg/bg-signin.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px;
}

.content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.title {
  margin-bottom: 8px;
}

.form {
  width: 400px;
}

.input {
  padding-bottom: 12px;
}

.inputError {
  & div {
    border: 1px solid $color-red;
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  padding-top: 12px;
}

.button {
  width: 182px;
}

.checkboxLabel {
  font-size: 15px;
  line-height: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox {
  appearance: none;
  width: 19px;
  height: 19px;
  border-radius: 3px;
  margin-right: 12px;
  border: 1px solid $color-green;
  background-color: $color-white;
  cursor: pointer;

  &:checked {
    background-color: $color-green;
    background-image: url('../../assets/icons/checkedV.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
