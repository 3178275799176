@import '../../../../../styles/helpers/variables.scss';

.title {
  margin-bottom: 24px;
}
.titleProgram {
  width: 100%;
  max-width: 990px;
  border-radius: 5px;
  border: 1px solid var(--unnamed, rgba(200, 205, 216, 0.6));
  background: #fff;
  height: 28px;
  padding: 10px 16px;
  &:hover {
    border: 1px solid $color-border-dark;
  }
  color: $color-black-title;

  &:focus {
    outline: none;
    border: 1px solid $color-border-dark;
  }
  & > div {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.subtitle {
  color: $color-grey-title;
  font-size: 15px;
  line-height: 18px;
}

.remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: none;
  background: transparent;
  cursor: pointer;

  & svg {
    fill: #c8cdd8;
  }

  &:hover svg {
    fill: tomato;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.desc {
  min-height: 100px;
  //   margin-bottom: 32px;
  :global {
    .ql-container {
      height: 83px;
    }
    .ql-editor {
      height: 83px;
      min-height: 83px;
      margin-bottom: 0;
    }
  }
}

.fullDesc {
  min-height: 500px;
  margin-bottom: 32px;

  :global {
    .ql-container {
      height: 500px;
    }

    .ql-editor {
      height: 500px;
      min-height: 500px;
    }
  }
}
