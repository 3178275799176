@import '../../styles/helpers/variables.scss';

.settings {
  display: flex;
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  content: '';
  top: 10px;
  right: 20px;
  width: 126px;
  background: $color-white;
  box-shadow: 2px 4px 10px rgba(91, 101, 114, 0.18);
  border-radius: 8px;
  z-index: 1;
}

.action {
  background-color: transparent;
  color: $color-black-title;
  border: none;
  padding: 8px 16px;
  min-height: 32px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: $color-table-bg;
    color: $blue-color-dark;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
}
