@import '../../styles/helpers/variables.scss';

.select {
  width: 334px;
  padding: 16px 0 16px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.label {
  font-size: 15px;
  color: $color-grey-label;
  margin-bottom: 10px;
  display: block;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $blue-color;
  border: 1px solid $blue-color;
  box-shadow: 2px 4px 8px #e5e8f3;
  border-radius: 8px;
  width: 155px;
  height: 38px;
  padding: 7px 33px;
  color: $color-white;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    background: $blue-color-dark;
    border: 1px solid $blue-color-dark;
  }
}

.list {
  background: $color-white;
  box-shadow: 2px 4px 10px rgba(91, 101, 114, 0.18);
  border-radius: 8px;
  z-index: 1;
  position: absolute;
  content: '';
  width: inherit;
  top: 70px;
}

.item {
  position: relative;
  background: $color-white;
  padding: 9px 16px;
  color: $color-black-title;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  &:hover {
    background: $color-table-bg;
    color: $blue-color-dark;
  }
}

.secondSelect {
  position: absolute;
  width: 100%;
  max-width: 334px;
  height: 40px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(91, 101, 114, 0.3);
  display: flex;
  flex-direction: column;
  left: -330px;
  z-index: 100;
  & > span {
    height: 100%;
    cursor: pointer;
    padding: 8px 16px;
    color: var(--unnamed, #263b53);
    font-family: Museo Sans Cyrl;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    border-bottom: 1px solid var(--unnamed, rgba(200, 205, 216, 0.3));
    background: #fff;
    &:hover {
      background: $color-table-bg;
      color: $blue-color-dark;
    }
    &:last-child {
      // border: none;
      border-radius: 0px 0px 8px 8px;
    }
  }
}
