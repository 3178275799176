@import '../../../../../../styles/helpers/variables.scss';

.tableWrapper {
  margin-bottom: 16px;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_hotel {
    width: 180px;
    border-radius: 8px 0px 0px 0px;
    padding-left: 16px;
  }

  &_period {
    width: 155px;
  }

  &_nightCount {
    width: 155px;
  }
  &_roomtype {
    width: 160px;
  }

  &_category {
    width: 160px;
  }

  &_accomodationType {
    width: 146px;
  }

  &_price {
    width: 108px;
  }

  &_tourist {
    width: 200px;
    padding-right: 16px;
    border-radius: 0px 8px 0px 0px;
  }
}

.cell {
  color: $color-black-title;
  padding: 9px 12px;
  font-size: 16px;
  min-height: 38px;
}

.headers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $color-table-bg;
  border-radius: 8px;
}

.row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
}

.hotel {
  width: 162px;
}

.roomtype {
  width: 165px;
}

.category {
  width: 170px;
}

.accomodationType {
  width: 156px;
}

.price {
  width: 108px;
}

.tourist {
  width: 205px;
}
.nightCount {
  width: 170px;
}
.period {
  width: 165px;
}

.newItem {
  border: none;

  &:active {
    border: none;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}

.delete {
  cursor: pointer;
  width: 20px;
}

.btnRow {
  padding-top: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.saveRow {
  padding: 4px 25px;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  background: var(--unnamed, #70a4cb);
  box-shadow: 2px 4px 8px 0px #e5e8f3;
  color: #fff;
  text-align: center;
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

.addRow {
  padding: 4px 25px;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  box-shadow: 2px 4px 8px 0px #e5e8f3;
  color: var(--unnamed, #70a4cb);
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}
