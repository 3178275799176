@import '../../../styles/helpers/variables.scss';

.edit {
  width: 100%;
  min-width: 1200px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  & {
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.top {
  width: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
}

.title {
  display: inline-block;
}

.content {
  width: 100%;
  max-width: 1277px;
  padding: 0 24px;
}
.remove {
  cursor: pointer;
}
