// Colors
$color-black-font: #131b21;
$color-black-title: #263b53;
$color-blue-logo: #6390b2;
$color-blue-primary: #5995c4;
$color-blue-light: #4389c0;
$color-blue-dark: #255e89;
$color-blue-font: #1a477b;
$color-grey-light: #f7fafc;
$color-grey-normal: #e5eef4;
$color-grey-dark: #7c8698;
$color-grey-bg: #a4a4a4;
$color-grey-font: #8a94a2;
$color-grey-title: #5b6572;
$color-grey-title-75: rgba(91, 101, 114, 0.75);
$color-grey-label: #737b87;
$color-orange-primary: #ff810c;
$color-orange-secondary: #ffb20c;
$color-white: #ffffff;
$color-white-light: #fcfcfc;
$color-white-normal: #f6f5f5;
$color-white-dark: #f7f8f9;
$color-white-primary: #e7e8e9;
$color-red: #ff5144;
$color-green: #009999;
$color-violet: #7084cb;

$blue-color: #70a4cb;
$blue-color-dark: #5d96c1;
$color-table-bg: #f5f6fa;
$color-frame-bg: #f9fafe;
$color-border-light: #eff0f3;
$color-border: #dee1e8;
$color-border-dark: #c8cdd8;
$color-border-dark-30: rgba(200, 205, 216, 0.3);
$color-border-dark-60: rgba(200, 205, 216, 0.6);
$color-green: #70cbbb;
$color-orange: #f7a860;
$color-orange-hover: rgb(247, 168, 96, 0.75);

// Device
$desktop-width: 1468px;
$tablet-width: 1119px;
$mobile-width: 767px;
