@import '../../../styles/helpers/variables.scss';

.tableWrapper {
  overflow: hidden;
  width: 100%;
  max-width: inherit;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}
.cons {
  background-color: #ffdf8b;
}
.reserve {
  background-color: #d4ffde;
}
.payed {
  background-color: #d4f5ff;
}
.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_id {
    min-width: 72px;
    border-radius: 8px 0px 0px 0px;
    cursor: pointer;
  }

  &_partner {
    min-width: 168px;
    cursor: pointer;
    @media (max-width: $desktop-width) {
      min-width: 120px;
    }
  }

  &_bookingDate {
    min-width: 135px;
    cursor: pointer;
  }

  &_status {
    min-width: 161px;
    cursor: pointer;
    @media (max-width: $desktop-width) {
      min-width: 80px;
    }
  }

  &_price {
    min-width: 124px;
    cursor: pointer;
  }

  &_tourTitle {
    min-width: 200px;
    width: 100%;
    border-radius: 0px 8px 0px 0px;
    @media (max-width: $desktop-width) {
      min-width: 120px;
    }
  }

  &_tourDate {
    min-width: 127px;
    cursor: pointer;
  }

  &_manager {
    width: 100%;
    max-width: 180px;
  }
}

.cell {
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 14px 16px;
  font-size: 16px;
  min-height: 73px;
}

.rows,
.row {
  display: flex;
}

.row {
  cursor: pointer;
}

.id {
  min-width: 72px;
}

.partner {
  min-width: 168px;
  @media (max-width: $desktop-width) {
    min-width: 120px;
  }
}

.bookingDate {
  min-width: 135px;
}

.status {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 161px;
  & > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media (max-width: $desktop-width) {
    min-width: 80px;
  }
}

.price {
  min-width: 124px;
}

.tourTitle {
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #263b53;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  & > span {
    color: rgba(91, 101, 114, 0.75);
  }
  min-width: 200px;
  width: 100%;
  @media (max-width: $desktop-width) {
    min-width: 120px;
  }
}

.tourDate {
  min-width: 127px;
}

.manager {
  width: 100%;
  max-width: 180px;
}

.count {
  padding: 14px 16px;
  color: $color-grey-title-75;
  font-size: 15px;
  line-height: 18px;
  border: none;
  height: 48px;
}

.notFound {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}

.loader {
  margin: 20px auto;
  width: 100%;
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
}

.hasUnread {
  background-color: #ffa7dd;
}
