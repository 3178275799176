@import '../../../../../styles/helpers/variables.scss';

.firstSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
  border-bottom: 2px solid rgba(200, 205, 216, 0.3);
}
.column {
  display: flex;
  align-items: flex-end;
}

.secondSection {
  position: relative;
  margin-bottom: 32px;
  border-bottom: 2px solid rgba(200, 205, 216, 0.3);
}

.tableWrapperB {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.section {
  margin-bottom: 32px;
}

.oneResult {
  width: 160px;
  margin-right: 16px;
}

.input {
  width: 160px;
  margin-right: 16px;
}

.partnerWrapper {
  display: flex;
  margin-bottom: 32px;
}

.partner {
  width: 314px;
  min-width: 314px;
  margin-right: 16px;
}
.partner label {
  color: #5b6572;
}
.partner input {
  color: #263b53;
}
.email {
  width: 314px;
  margin-right: 16px;
}

.button {
  width: 223px;
  height: 38px;
  align-self: flex-end;
  position: relative;
  bottom: 18px;
}

.responsible {
  width: 435px;
  margin-right: 16px;
}

.order {
  width: 300px;
}

.accordiontSection {
  max-width: 716px;
  border-bottom: 2px solid rgba(200, 205, 216, 0.3);
  margin-bottom: 24px;
  font-family: 'Museo Sans Cyrl';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #263b53;
}

.accommodation {
  margin-bottom: 35px;
}

.subTitle {
  color: $color-grey-title;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 18px;
}

.desc {
  min-height: 100px;
  margin-bottom: 32px;

  :global {
    .ql-container {
      height: 83px;
    }

    .ql-editor {
      height: 83px;
      min-height: 83px;
    }
  }
}

.modalForm {
  position: absolute;
  width: 100%;
  max-width: 272px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(91, 101, 114, 0.25);
  padding: 20px;
  top: 93px;
  right: 24%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: 16px;
  &:before {
    content: '';
    border: 12px solid transparent;
    border-bottom: 12px solid white;
    position: absolute;
    left: 25px;
    margin-left: -12px;
    top: -23px;
    width: 0;
  }
}

.close {
  position: absolute;
  top: 17px;
  right: 11px;
  background: none;
  border: none;
  cursor: pointer;
}
.amount {
  padding: 0;
  & div {
    padding: 0;
  }

  & input {
    width: 100%;
    border: 1px solid rgba(200, 205, 216, 0.6);
    border-radius: 5px;
    height: 38px;
    padding: 10px 16px;
  }
}
.rate {
  padding: 16px 0;
  margin-right: 16px;
  max-width: 160px;
  & div {
    padding: 0;
  }

  & input {
    width: 100%;
    border: 1px solid rgba(200, 205, 216, 0.6);
    border-radius: 5px;
    height: 38px;
    padding: 10px 16px;
  }
}
.formButton {
  width: 100%;
  padding: 7px 0;
  background: #f7a860;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-top: 16px;
  border: 1px solid #f7a860;
  box-shadow: 2px 4px 8px #e5e8f3;
  border-radius: 8px;
  &:hover {
    background: #f7a860 !important;
  }
}

.calendar {
  padding: 0;
}
.paymentType {
  padding-top: 5px;
  padding-bottom: 0;
}
.paymentType div {
  background: white;
  border: 1px solid rgba(200, 205, 216, 0.6);
  border-radius: 5px;
}
.suplierPrice {
  width: 160px;
}
.managerDiscont {
  width: 160px;
}
.managerDiscontPercent {
  width: 160px;
}

.disabled > div > span {
  background: #f1f3f5 !important;
  opacity: 0.6 !important;
  border: none !important;
}
.disabledBtn {
  opacity: 0.6;
  cursor: default;
}

.inputGroup {
  display: flex;
  gap: 16px;
}
