@import '../../../../../../styles/helpers/variables.scss';

.hotels {
  max-width: 1118px;
}

.tableWrapper {
  overflow: hidden;
}

.table {
  background: $color-white;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-black-title;
  height: 40px;
  background-color: $color-table-bg;
  text-align: left;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 12px;
}

.cell {
  color: $color-black-title;
  padding: 9px 12px;
  font-size: 15px;
  min-height: 38px;

  border-radius: 5px;
  //   margin-right: 12px;
  flex-shrink: 0;
}

.headers {
  display: flex;
  background-color: $color-table-bg;
  border-radius: 8px;
}

.row {
  display: flex;
  align-items: center;
}

.row {
  border-bottom: 1px solid $color-border-dark-30;
  min-height: 38px;

  display: flex;
  gap: 12px;
}

.number {
  max-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  background-color: white;
}

.name {
  width: 752px;
}

.newItem {
  border: none;

  &:active {
    border: none;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}

.delete {
  cursor: pointer;
}
