@import '../../../../../styles/helpers/variables.scss';

.form {
  display: flex;
  justify-content: space-between;
}

.contacts {
  width: 462px;
  padding-right: 24px;
}

.history {
  width: 816px;
  padding-left: 32px;
  padding-right: 32px;
  border-left: 2px solid $color-border-dark-30;
}

.partner {
  width: 100%;
}

.user {
  margin-right: 16px;
}

.tabs {
  width: 100%;
  margin: 0 0 24px;
  padding-top: 16px;
  height: 49px;

  & ul {
    padding-left: 0;
  }
}

.historyTop {
  display: flex;
}

.editor {
  padding-left: 48px;
}

.refundAmount {
  // Chrome:
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Firefox:
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']:hover,
  input[type='number']:focus {
    -moz-appearance: number-input;
  }
}

.loaderPartner {
  margin: 150px auto;
  width: 100%;
}
