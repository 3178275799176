@import '../../styles/helpers/variables.scss';

.addService {
  padding: 16px 0 16px;
  width: 1148px;
}
.serviceContainer {
  padding: 16px 0 16px;
  width: 1100px;
  .column_price {
    width: 145px;
  }
}
.top {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;

  &:hover {
    color: $color-black-font;
  }

  &::after {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    background-image: url('../../assets/icons/downContur.svg');
    background-repeat: no-repeat;
    background-size: 10px 10px;
    top: calc(50% - 4px);
    right: -25px;
    transition: all 0.2s ease-in;
  }
}

.removeWrapper {
  display: flex;
  margin-top: -40px;
  align-items: end;
  justify-content: end;
}
.remove {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  cursor: pointer;

  & svg {
    fill: #5b6572;
  }

  &:hover svg {
    fill: tomato;
  }
}

.topUp {
  &::after {
    transition: all 0.2s ease-in;
    transform: rotate(180deg);
    top: 0;
    right: -25px;
  }
}

// .tableWrapper {
//   overflow: hidden;
//   width: inherit;
// }

.table {
  background: $color-white;
  border: 1px solid $color-border-dark-30;
  border-radius: 8px 8px 0 0;
  margin-top: 12px;
  border-collapse: collapse;
  // overflow-x: auto;
}

.column {
  color: $color-black-title;
  height: 40px;
  border-bottom: 1px solid $color-border-dark-30;
  border-right: 1px solid $color-border-dark-30;
  background-color: $color-table-bg;
  text-align: left;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_service {
    width: 578px;
  }
  &_sex {
    width: 322px;
  }
  &_price {
    width: 104px;
  }
}

.cell {
  color: $color-black-title;
  padding: 10px 12px;
  font-size: 15px;
  min-height: 38px;
  border-right: 1px solid $color-border-dark-30;
}
.rows {
  background-color: $color-table-bg;
}
.rows,
.row {
  display: flex;
}

.row {
  border-bottom: 1px solid $color-border-dark-30;
  min-height: 38px;

  &:last-child {
    border-bottom: none;
  }
}
.service {
  width: 100%;
  max-width: 578px;
}
.sex {
  width: 322px !important;
  padding: 0 !important;
  & > ul {
    width: 322px !important;
  }
  & > div {
    background: none !important;
    border: none !important;
    text-align: left !important;
  }
}
.price {
  width: 104px;
  border-right: 1px solid $color-border-dark-30 !important;
}

.button {
  margin-top: 16px;
}

.newRow {
  display: flex;
  border: 1px solid $color-border-dark-30;
  border-top: none;
}

.newItem {
  color: $color-black-title;
  padding: 10px 12px;
  font-size: 15px;
  min-height: 38px;
  border: none;
  border-top: none;
  border-right: 1px solid $color-border-dark-30;

  &:last-child {
    border-right: none;
  }

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
