@import '../../styles/helpers/variables.scss';

.select {
  width: 100%;
  padding: 16px 0 16px;
  position: relative;
}

.label {
  font-size: 15px;
  color: $color-grey-label;
  margin-bottom: 10px;
  display: block;
}

.header {
  background: #f5f6fa;
  border-radius: 8px 8px 8px 8px;
  height: 38px;
  padding: 10px 16px;
  color: $color-black-title;
  cursor: pointer;
  position: relative;
  border: 1px solid #f5f6fa;
  line-height: 18px;

  // &::after {
  //   position: absolute;
  //   width: 9px;
  //   height: 5px;
  //   content: '';
  //   right: 16px;
  //   top: 50%;
  //   background-image: url('../../assets/icons/downContur.svg');
  //   background-position: 50% 50%;
  //   background-size: 9px 5px;
  // }
}

.headerOpened {
  border-radius: 8px 8px 0px 0px;

  &::after {
    transform: rotate(180deg);
  }
}

.iconWrapper {
  margin-right: 16px;
}

.list {
  box-shadow: 0px 4px 8px rgba(91, 101, 114, 0.25);
  border-radius: 0px 0px 8px 8px;
  z-index: 1;
  overflow-y: scroll;
  height: 200px;
  position: absolute;
  content: '';
  width: inherit;
}

.item {
  background: $color-white;
  padding: 8px 16px;
  color: $color-black-title;
  font-size: 15px;
  cursor: pointer;

  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  &:hover {
    background: #f5f6fa;
  }
}

.placeholder {
  color: $color-grey-title-75;
}

.error {
  border: 1px solid $color-red;
}
.disabled {
  background: #f1f3f5;
  opacity: 0.6;
}
