@import '../../styles/helpers/variables.scss';

.block {
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px;

  &:focus-within .inputWrapper {
    border: 1px solid $color-border-dark;
  }
}

.label {
  color: $color-grey-title;
  margin-bottom: 10px;
  font-size: 15px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border: 1px solid rgba(200, 205, 216, 0.6);
  border-radius: 5px;
  padding: 10px 16px;
  width: 100%;
  line-height: 18px;
  height: 38px;

  &:hover {
    border: 1px solid $color-border-dark;
  }
}

.input {
  border: none;
  width: 100%;
  color: $color-black-title;

  &:focus {
    outline: none;

    &.inputWrapper {
      border: 1px solid $color-border-dark;
    }
  }
}

.iconLeft {
  //   padding-left: 16px;
  padding-right: 16px;
}

.error {
  &:focus-within .inputWrapper {
    border: 1px solid $color-red;
  }
  .inputWrapper {
    border: 1px solid $color-red;
  }
}
