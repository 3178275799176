.navbar {
  background-size: cover;
  box-shadow: (0px 4px 4px rgba(25, 33, 41, 0.2));
  position: fixed;
  padding: 43px 4px;
  height: calc(100vh - 60px);
  transition: '.25s ease-in-out';
  background: linear-gradient(
      180deg,
      #446683 0%,
      rgba(68, 102, 131, 0.94) 43.59%,
      rgba(57, 85, 113, 0) 64%,
      rgba(42, 65, 89, 0.68) 90.34%,
      #263b53 100%
    ),
    url('../../../../assets/bg/bg-navbar3.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  min-width: 62px;
  z-index: 99;
}

.navbarOpen {
  min-width: 207px;
}
