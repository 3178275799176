@import '../../../styles/helpers/variables.scss';

.tableWrapper {
  overflow: hidden;
  width: 100%;
  max-width: inherit;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;

  &_id {
    min-width: 72px;
    border-radius: 8px 0px 0px 0px;
    cursor: pointer;
  }

  &_partner {
    min-width: 200px;
    cursor: pointer;
  }

  &_comment {
    min-width: 190px;
    max-width: 190px;
    width: 100%;
    border-radius: 0px 8px 0px 0px;
    @media (max-width: $desktop-width) {
      min-width: 120px;
    }
  }

  &_phone {
    min-width: 200px;
  }

  &_email {
    width: 100%;
    max-width: 200px;
    min-width: 200px;
  }
  &_status {
    width: 100%;
    max-width: 200px;
  }
}

.cell {
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 14px 16px;
  font-size: 16px;
  height: 73px;
}

.row {
  cursor: pointer;
}

.id {
  min-width: 72px;
}

.partner {
  min-width: 300px;
  @media (max-width: $desktop-width) {
    min-width: 200px;
  }
}

.comment {
  width: 100%;
  max-width: 259px;
}

.phone {
  min-width: 200px;
  @media (max-width: $desktop-width) {
    min-width: 72px;
  }
}

.email {
  width: 100%;
  max-width: 245px;
  min-width: 245px;
}

.type {
  color: $color-grey-title-75;
  margin-top: 6px;
}

.count {
  padding: 14px 16px;
  color: $color-grey-title-75;
  font-size: 15px;
  line-height: 18px;
  border: none;
  height: 48px;
}

.notFound {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}

.loader {
  margin: 20px auto;
  width: 100%;
}

.loaderPartner {
  margin: 150px auto;
  width: 100%;
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
}

.greenColor {
  background-color: #e1ffda;
}
