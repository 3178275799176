.form {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.form input {
  display: none;
}
.form label {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 2px dashed var(--unnamed, #7084cb);
}
.form p {
  max-width: 224px;
  color: var(--unnamed, rgba(91, 101, 114, 0.75));
  text-align: center;
  font-family: Museo Sans Cyrl;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  & > span {
    color: var(--unnamed, #5d96c1);
    position: relative;
    &::after {
      content: '';

      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0.5px;

      border-bottom: 1px solid var(--unnamed, #5d96c1);
    }
  }
}
.uploadBtn {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.uploadBtn:hover {
  text-decoration-line: underline;
}
