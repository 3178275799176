.item {
    background-color: #FFFFFF;
    border: none;

}

.title {
    width: 25%;
    font-family: 'Museo Sans Cyrl';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #263B53;
    padding-left: 0;
    margin-bottom: 8px;

}

.panel {
    margin-left: -1rem;
}