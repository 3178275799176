@import '../../../styles/helpers/variables.scss';

.tableWrapper {
  overflow: hidden;
  width: 100%;
  max-width: inherit;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_id {
    min-width: 72px;
    border-radius: 8px 0px 0px 0px;
    cursor: pointer;
  }

  &_name {
    min-width: 500px;
    cursor: pointer;
  }

  &_count {
    min-width: 140px;
    cursor: pointer;
  }

  &_edit {
    min-width: 150px;
    cursor: pointer;
  }

  &_tags {
    min-width: 360px;
    cursor: pointer;
  }
  &_settings {
    width: 100%;
    min-width: 80px;
  }
}

.cell {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 14px 16px;
  font-size: 16px;
  min-height: 73px;
}
.rows,
.row {
  display: flex;
}

.header {
  display: flex;
}
.row {
  cursor: pointer;
  flex-direction: column;
}
.content {
  background: var(--unnamed, #f5f6fa);
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(5, 220px);
  gap: 36px;
  flex-wrap: wrap;
}

.count {
  padding: 14px 16px;
  color: $color-grey-title-75;
  font-size: 15px;
  line-height: 18px;
  border: none;
  height: 48px;
}

.notFound {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}
.id {
  width: 72px;
}
.partner {
  width: 500px;
}
.bookingDate {
  width: 140px;
}
.price {
  width: 150px;
}
.tourTitle {
  width: 360px;
}
.iconGroup {
  display: flex;
  align-items: center;
  gap: 20px;
}

.loader {
  margin: 20px auto;
  width: 100%;
}
.remove {
  cursor: pointer;
}
.opened {
  transform: rotate(180deg);
  padding-top: 10px;
}

.photo {
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: var(--unnamed, rgba(91, 101, 114, 0.75));
  text-align: center;
  font-family: Museo Sans Cyrl;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  & > img {
    width: 100%;
    height: 140px;
    border-radius: 10px;
  }
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
}
