@import '../../../styles/helpers/variables.scss';

.file {
  width: 100%;
  max-width: max-content;
  min-height: 60px;
  max-height: 120px;
  border-radius: 15px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px 16px;
  position: relative;
}

.imgWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.name {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: $color-black-title;
}

.icon {
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
