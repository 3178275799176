@import '../../../../styles/helpers/variables.scss';

.tabs {
  margin-bottom: 16px;
  margin-left: 0;

  & ul {
    padding-left: 0;
  }
}

.select {
  margin-bottom: 16px;
}
.selectComp {
  & label {
    color: $color-grey-title;
    margin-bottom: 10px;
    font-size: 15px;
  }
  & div {
    background: none;
    border: 1px solid rgba(200, 205, 216, 0.6);
    border-radius: 5px;
  }
}
.group {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.statusSelect {
  max-width: 230px;
  width: 100%;
}
.dobbleGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.textarea {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
}

.textareaLabel {
  font-size: 15px;
  line-height: 18px;
  color: $color-grey-title;
  margin-bottom: 10px;
}

.textareaText {
  height: 74px;
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.6);
  border-radius: 5px;
  padding: 10px 16px;
  resize: none;
  color: $color-black-title;
  overflow: hidden;

  &:focus {
    outline: none;
  }
}

.mtAuto {
  margin-top: auto;
}

.textareaTextError {
  border: 1px solid $color-red;

  &:focus {
    outline: none;
  }
}

.requisitesTop {
  grid-template-columns: 225px repeat(3, 157px);
}
.groupThree {
  grid-template-columns: 180px 311px 189px;
  align-items: flex-end;
}
.requisitesBottom {
  grid-template-columns: repeat(3, 1fr);
}

.address {
  max-height: 58px;
}

.commonForm {
  margin-bottom: 22px;
}

.requisitesForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  padding: 10px 16px;
  border-radius: 8px;
  background: var(--unnamed, #f5f6fa);
}
.contract {
  width: 180px;
}
.deleteBtn {
  margin-left: auto;
  cursor: pointer;
  border: none;
  background: none;
}
.reqTitle {
  padding-top: 16px;
  padding-bottom: 8px;
}

.number {
  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
  & input[type='number']:hover,
  & input[type='number']:focus {
    -moz-appearance: number-input;
  }

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.applicationBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 24px;
  gap: 16px;
  & > h2 {
    color: #263b53;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.addFileBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  & > label {
    cursor: pointer;
    display: flex;
    padding: 5px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
    border: 1px solid var(--neutral-5, #d9d9d9);
    background: var(--neutral-1, #fff);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}
.addFile {
  display: none;
}
.requisitesTab {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 8px;
  padding-bottom: 24px;
}
.top {
  display: flex;
  align-items: center;
  gap: 6px;
}

.add {
  width: 19px;
  height: 19px;
  background: $blue-color;
  box-shadow: 2.11111px 4.22222px 8.44444px #e5e8f3;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  cursor: pointer;
}

.editBlock {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  padding: 10px;
  & > button {
    background-color: $color-orange;

    &:hover {
      background-color: $color-orange-hover;
    }
  }
}

.addRow {
  margin: 16px 0;
  padding: 4px 25px;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  background: var(--unnamed, #70a4cb);
  box-shadow: 2px 4px 8px 0px #e5e8f3;
  color: #fff;
  text-align: center;
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
