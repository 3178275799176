@import '../../../styles/helpers/variables.scss';

.edit {
  width: 100%;
  min-width: 1033px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  & {
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.top {
  position: fixed;
  width: 1033px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px 10px;
  z-index: 1;
}

.title {
  display: inline-block;
}

.content {
  flex: 1 0;
  max-width: 1277px;
  padding: 0 24px;
}
.remove {
  cursor: pointer;
}

.table {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--unnamed, rgba(200, 205, 216, 0.3));
  background: var(--unnamed, #f5f6fa);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
}

.notFound {
  top: 25%;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}

.loader {
  margin: 20px auto;
  width: 100%;
}
.article {
  width: 100%;
  max-width: 220px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
  border: 2px solid transparent;
  & > p {
    max-width: 220px;
    color: var(--unnamed, rgba(91, 101, 114, 0.75));
    text-align: center;
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
}
.imgContainer {
  width: 100%;
  height: 140px;

  position: relative;
  border-radius: 10px;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
  & > span {
    position: absolute;
    border-radius: 0px 10px 0px 0px;
    background: var(--unnamed, #7084cb);
    padding: 5px 10px;
    color: #fff;
    text-align: center;
    font-family: Museo Sans Cyrl;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    top: 0;
    right: 0;
  }
}

.selected {
  border: 2px solid var(--unnamed, #70a4cb);
}

.filters {
  padding-top: 80px;
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
}
.counts {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  & > span {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
