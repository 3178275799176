@import '../../styles/helpers/variables.scss';

.search {
  width: 300px;
  height: 38px;
  background: $color-white;
  border: 1px solid #e7eaed;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  line-height: 22px;
  color: $color-grey-title-75;
}

.input {
  width: 100%;
  color: $color-grey-font;
  padding: 8px;
  margin: 8px;
  border: none;

  &::placeholder {
    color: $color-grey-font;
  }

  &:focus {
    outline: none;
  }
}

.button {
  background-color: transparent;
  border: none;
  color: $color-blue-primary;
  font-weight: 400;
  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }

  &:hover {
    color: $color-blue-dark;
  }
}

.close {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}