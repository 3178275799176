.lkBtn {
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 500;
  background-color: #4cc46e;
  margin-bottom: 36px;
  margin-top: -20px;
  &:hover {
    background-color: #389151;
  }
}
