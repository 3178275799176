.top {
  padding: 32px 0 12px;
  width: 100%;
}

.counteragents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.search {
  display: flex;
  gap: 16px;
}
