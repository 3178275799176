@import '../../../../../styles/helpers/variables.scss';

.textModal {
  font-family: 'Museo Sans Cyrl';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #263b53;
  text-align: center;
}

.buttons {
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 5px;
  margin-top: 40px;

  & button {
    width: 146px;
  }
}

.btnCancel {
  background-color: transparent;
  color: $color-grey-title;
  border-radius: 8px;
  box-shadow: none;

  &:hover {
    background: #f5f6fa;
  }
}
