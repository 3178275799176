@import '../../../../../../styles/helpers/variables.scss';

.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: $color-white;
  width: 100%;
  height: 38px;
  background: transparent;
  border-radius: 2px;
  padding: 8px 16px;
  margin-bottom: 26px;

  & svg {
    fill: $color-grey-normal;
  }

  &:hover {
    background: $color-blue-primary;
  }

  &:active {
    transform: translateY(1px);
  }
}

.linkActive {
  background: $color-blue-primary;

  & svg {
    fill: $color-white;
  }
}

.title {
  margin-left: 16px;
}
