.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  padding-bottom: 32px;
  padding-left: 53px;
}
.row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 2px solid var(--unnamed, rgba(200, 205, 216, 0.3));
}
.requestType {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.requestType_title {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  & > p {
    margin: 0;
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & > span {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.dateTimeBlock {
  display: flex;
  gap: 64px;
}
.requestInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > h2 {
    color: #263b53;
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & > p {
    margin: 0;
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 671px;
  }
}
.requestInfo_title {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  & > p {
    margin: 0;
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & > span {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.columnBlock {
  display: flex;
  gap: 32px;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  & > p {
    width: 100%;
    max-width: 150px;
    margin: 0;
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & > span {
    max-width: 521px;
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
