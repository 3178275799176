@import '../../../../styles/helpers/variables.scss';

.tableWrapper {
  overflow: hidden;
  width: 100%;
  max-width: inherit;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_id {
    min-width: 72px;
    border-radius: 8px 0px 0px 0px;
    cursor: pointer;
  }

  &_title {
    min-width: 350px;
    cursor: pointer;
    @media (max-width: $desktop-width) {
      min-width: 220px;
    }
  }

  &_bookingDate {
    min-width: 232px;
    @media (max-width: $desktop-width) {
      min-width: 140px;
      max-width: 232px;
      width: 100%;
    }
  }

  &_tourOperator {
    min-width: 172px;
  }

  &_route {
    min-width: 200px;
    width: 100%;
  }
}

.cell {
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 14px 16px;
  font-size: 16px;
  min-height: 73px;
}

.rows,
.row {
  display: flex;
}

.row {
  cursor: pointer;
}

.id {
  min-width: 72px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #263b53;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  & > span {
    color: rgba(91, 101, 114, 0.75);
  }
  min-width: 350px;
  @media (max-width: $desktop-width) {
    min-width: 220px;
  }
}

.bookingDate {
  min-width: 232px;
  @media (max-width: $desktop-width) {
    min-width: 140px;
    max-width: 232px;
    width: 100%;
  }
}

.tourOperator {
  min-width: 172px;
}

.route {
  min-width: 200px;
  width: 100%;
}

.count {
  padding: 14px 16px;
  color: $color-grey-title-75;
  font-size: 15px;
  line-height: 18px;
  border: none;
  height: 48px;
}

.notFound {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}

.loader {
  margin: 20px auto;
  width: 100%;
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
}
