@import '../../styles/helpers/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
}

.addFile {
  display: none;
}

.addFileLabel {
  display: inline-block;
  color: $blue-color;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  transition: all 0.2s ease-in;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    color: $blue-color-dark;
    background-color: transparent;
    transition: all 0.2s ease-in;
  }
}

.files {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.disabledBtn {
  opacity: 0.6;
  cursor: default;
}
