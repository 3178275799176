@import '../../styles/helpers/variables.scss';

.select {
  min-width: 398px;
  position: relative;
  padding: 16px 0;
}

.label {
  font-size: 15px;
  line-height: 18px;
  color: $color-grey-label;
  margin-bottom: 10px;
  display: inline-block;
}

.header {
  height: 38px;
  background: $color-table-bg;
  border-radius: 5px 5px 5px 5px;
  padding: 9px 16px;
  cursor: pointer;
  position: relative;

  &::placeholder {
    color: $color-grey-title-75;
  }
}

.headerOpened {
  border-radius: 5px 5px 0px 0px;

  &::after {
    transform: rotate(180deg);
    transition: all 0.2s ease-in;
  }
}

.dropdown {
  position: absolute;
  box-shadow: 1px 4px 10px rgba(91, 101, 114, 0.18);
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  background: $color-white;
  z-index: 1;
}

.list {
  max-height: 360px;
  overflow-y: auto;
}
.itemWithSubName {
  flex-direction: column;
  height: auto !important;
  align-items: flex-start !important;
  gap: 6px;
  color: #263b53;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  & > span {
    color: rgba(91, 101, 114, 0.75);
  }
}
.item,
.itemWithSubName {
  height: 40px;
  padding: 9px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $color-table-bg;

    & .btnItem {
      background: rgba(255, 255, 255, 0.6);

      &:hover {
        background-color: $color-border-dark-60;
      }
    }
  }
}

.itemSelected {
  background-color: $color-table-bg;
}

.btnItem {
  width: 28px;
  height: 24px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  rotate: 270deg;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create {
  bottom: 9px;
  color: $blue-color;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  transition: all 0.2s ease-in;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  padding-left: 16px;
  height: 40px;

  &:hover {
    color: $blue-color-dark;
    background-color: transparent;
    transition: all 0.2s ease-in;
  }
}

.search {
  width: 90%;
  outline: none;
  border: none;
  background: transparent;
}

.error {
  border: 1px solid $color-red;
}
.disabled {
  background: #f1f3f5;
  opacity: 0.6;
}
