@import '../../../../../../styles/helpers/variables.scss';
.groupParams {
  display: flex;
  flex-direction: column;
  max-width: 1010px;
  padding-bottom: 16px;
  border-bottom: 2px solid $color-border-dark-30;
}
.parameters {
  display: flex;
  gap: 16px;
  width: 100%;
}
.dublicateBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid $color-border-dark-30;
  max-width: 1010px;
}
.headerBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
.dateBlock {
  width: 100%;
  padding: 10px 12px;
  display: flex;
  gap: 12px;
  border-radius: 5px;
  background-color: #f5f6fa;
  min-height: 49px;
  flex-wrap: wrap;
  & > div {
    background-color: #fff;
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    gap: 16px;
    align-items: center;
    & span {
      margin: 0;
      padding: 0;
      cursor: pointer;
      margin-bottom: 5px;
    }
  }
}
.input {
  width: 189px;
  & div {
    background: $color-table-bg;
    border-radius: 5px;
    border: none;
  }
  & input {
    background: $color-table-bg;
  }
}

.calendar {
  width: 189px;
  & input {
    height: 38px;
    background: $color-table-bg;
    border-radius: 5px;
    border: none;
  }
}

.calendarError input {
  outline: 1px solid $color-red;
}

.priceOneDay {
  width: 260px;
}
.selector {
  width: 189px;
}
.sales {
  width: 100px;
}
.agent_sales {
  width: 190px;
}

.selectorError div {
  outline: 1px solid $color-red;
}

.addTrip {
  margin: 15px 0;
  background-color: $color-orange;

  &:hover {
    background-color: $color-orange-hover;
  }
}

.number {
  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
  & input[type='number']:hover,
  & input[type='number']:focus {
    -moz-appearance: number-input;
  }

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.fixedContainer {
  width: 100%;
  position: fixed;
  left: 19%;
  bottom: 0;
  background: white;
  padding: 10px;
  & > button {
    margin-left: 15px;
  }
}

.addRow {
  margin-top: auto;
  margin-bottom: 17px;
  margin-left: auto;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  padding: 8px 16px;
}
