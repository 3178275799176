@import '../../../../../styles/helpers/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}
.btnSelect {
  width: 218px;
  margin-left: auto;
  & > div {
    background-color: #70a4cb !important;
    color: #fff !important;
    & > span {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
}
.addTrip {
  width: max-content;
  margin: 15px 0;
  background-color: $color-orange;

  &:hover {
    background-color: $color-orange-hover;
  }
}
.photoSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  & > h3 {
    color: #263b53;
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
  }
}
.photoGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 380px);
  gap: 6px;
}

.photo {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--unnamed, #f5f6fa);
  cursor: grab;
}

.imageContainer {
  position: relative;
  width: 100%;
  max-width: 135px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
  & > img {
    width: 135px;
    height: 80px;
  }
  & > span {
    position: absolute;
    border-radius: 0px 6px 0px 0px;
    background: var(--unnamed, #7084cb);
    padding: 2px 8px;
    color: #fff;
    text-align: center;
    font-family: Museo Sans Cyrl;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    top: 0;
    right: 0;
  }
}

.photo_title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > h3 {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & > p {
    margin: 0;
    color: var(--unnamed, rgba(91, 101, 114, 0.75));
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & > div > svg {
    margin-top: 2px;
  }
  & > div > p {
    margin: 0;
    color: var(--unnamed, rgba(91, 101, 114, 0.75));
    font-family: Museo Sans Cyrl;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.remove {
  cursor: pointer;
}

.btnGroup {
  display: flex;
  margin-left: auto;
  gap: 16px;
}
.albumSection {
  display: grid;
  grid-template-columns: repeat(3, 360px);
  gap: 16px;
  margin-bottom: 24px;
}

.album {
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--unnamed, #f5f6fa);
}

.album_title {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.albumUsed {
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
  & > p {
    margin: 0;
    color: var(--unnamed, rgba(91, 101, 114, 0.75));
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    & > span {
      color: #263b53;
    }
  }
  & > span {
    padding-top: 6px;
  }
}

.helpBlock {
  width: max-content;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(91, 101, 114, 0.18);
  color: var(--unnamed, #263b53);
}
.durationHelp {
  position: absolute;
  top: 28px;
  left: 140px;
  max-width: 220px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 99999;
}
.photoHelp {
  position: absolute;
  top: 30px;
  left: 100px;
  max-width: 220px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 99999;
}
