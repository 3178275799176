@import '../../../styles/helpers/variables.scss';

.tableWrapper {
  padding-top: 8px;
  overflow: hidden;
  width: 100%;
  max-width: inherit;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > span {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.table {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--unnamed, rgba(200, 205, 216, 0.3));
  background: var(--unnamed, #f5f6fa);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 36px;
}

.notFound {
  top: 25%;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}

.loader {
  margin: 20px auto;
  width: 100%;
}
.article {
  width: 100%;
  max-width: 220px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  & > p {
    max-width: 220px;
    color: var(--unnamed, rgba(91, 101, 114, 0.75));
    text-align: center;
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
}
.imgContainer {
  width: 100%;
  height: 140px;

  position: relative;
  border-radius: 10px;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
  & > span {
    position: absolute;
    border-radius: 0px 10px 0px 0px;
    background: var(--unnamed, #7084cb);
    padding: 5px 10px;
    color: #fff;
    text-align: center;
    font-family: Museo Sans Cyrl;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    top: 0;
    right: 0;
  }
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  & > span {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
