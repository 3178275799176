@import '../../../styles/helpers/variables.scss';

.edit {
  width: 100%;
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  & {
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid rgba(200, 205, 216, 0.3);
  padding: 24px 32px 24px;
  margin-bottom: 16px;
}
.nameHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content {
  padding: 0 24px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.status {
  font-size: 15px;
  color: $color-grey-title;
  margin-top: 6px;
}

.lkTag {
  padding: 10px 20px;
  border: 1px solid #1573bb;
  background-color: transparentize(#1573bb, 0.9);
  color: #1573bb;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
}
