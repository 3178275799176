@import '../../../../../styles/helpers/variables.scss';

.parameters {
  padding-bottom: 32px;
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  padding: 16px 17px;
  background: $color-table-bg;
  border-radius: 8px;
}

.row {
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 2px solid $color-border-dark-30;
  margin-top: 16px;
  margin-bottom: 16px;
}

.subtitle {
  width: 200px;
  height: 49px;
  background: $color-table-bg;
  border-radius: 5px;
  font-size: 15px;
  line-height: 18px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.parameter {
  padding: 0;
  & div {
    min-height: 49px;

    &::after {
      background-image: none;
    }
  }
}
.helper {
  margin-top: 4px;
}
.helpBlock {
  position: absolute;
  width: 100%;
  max-width: 235px;
  top: -50px;
  left: 55px;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(91, 101, 114, 0.18);
  color: var(--unnamed, #263b53);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}

.item {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
  & > p {
    margin: 0;
    color: var(--unnamed, #263b53);
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.itemChecked {
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70cbbb);
  background: #fff;
}
.notChecked {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  border: none;
  background: transparent;
  background-image: url(../../../../../assets/icons/notChecked.svg);
  background-repeat: no-repeat;
  background-size: 19px 19px;
  background-position: 50% 50%;
  padding: 0;

  cursor: pointer;
}

.checked {
  background-image: url(../../../../../assets/icons/checkedTag.svg);
}
