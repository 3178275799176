@import '../../../../styles/helpers/variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 26px;
}
.image {
  width: 100%;
  max-width: 744px;
  border-radius: 10px;
}
.useInfo {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}
.useInfoItem {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  & > p {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    & > span {
      color: var(--unnamed, #263b53);
    }
  }
  & > span {
    padding-top: 8px;
  }
}
.inputGroup {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  & > div {
    width: 100%;
  }
}
.helpBlock {
  width: max-content;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(91, 101, 114, 0.18);
  color: var(--unnamed, #263b53);
}
.durationHelp {
  position: absolute;
  top: 35px;
  right: -205px;
  max-width: 220px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 99999;
}
.row {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subtitle {
  color: #737b87;
  font-family: Museo Sans Cyrl;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.listInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  & li {
    color: var(--unnamed, #5b6572);
    font-family: Museo Sans Cyrl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
