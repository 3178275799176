@import '../../../../styles/helpers/variables.scss';

.tabs {
  margin-bottom: 16px;
  margin-left: 0;

  & ul {
    padding-left: 0;
  }
}

.group {
  display: flex;
  justify-content: space-between;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.input {
  width: 237px;
}

.gender,
.birthDate,
.phone,
.email {
  width: 364px;
}

.textarea {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  margin-bottom: 38px;
}

.mtAuto {
  margin-top: auto;
}

.textareaLabel {
  font-size: 15px;
  line-height: 18px;
  color: $color-grey-title;
  margin-bottom: 10px;
}

.textareaText {
  height: 74px;
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.6);
  border-radius: 5px;
  padding: 10px 16px;
  resize: none;
  color: $color-black-title;
  overflow: hidden;

  &:focus {
    outline: none;
  }
}

.number {
  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
  & input[type='number']:hover,
  & input[type='number']:focus {
    -moz-appearance: number-input;
  }

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.editBlock {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  padding: 10px;
  & > button {
    background-color: $color-orange;

    &:hover {
      background-color: $color-orange-hover;
    }
  }
}
