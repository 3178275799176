@import '../../styles/helpers/variables.scss';

.button {
  padding: 8px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  box-shadow: 0px 4px 8px #e5e8f3;
  border-radius: 8px;
  color: $color-white;
  background: $blue-color;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: $blue-color-dark;
  }

  &:active {
    background-color: $blue-color;
  }
}

.buttonTransparent {
  color: $blue-color;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  transition: all 0.2s ease-in;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  height: 40px;
  margin-top: -16px;

  &:hover {
    color: $blue-color-dark;
    background-color: transparent;
    transition: all 0.2s ease-in;
  }

  &:active {
    color: $blue-color;
    background-color: transparent;
    transition: all 0.2s ease-in;
  }

  &:disabled {
    cursor: auto;
  }
}
