.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chatBlock {
  height: 700px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.chat {
  width: 100%;
  height: 100%;
  // min-height: 700px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  position: relative;
  padding-top: 24px;
}

.date {
  position: sticky;
  top: 10px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #e2e6f5;
  color: #263b53;
  font-weight: 500;
}

.messageWrap {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > span {
    color: #5c6c7e;
    font-size: 14px;
    font-weight: 500;
    margin-left: 6px;
  }
}
.message {
  width: 100%;
  max-width: 600px;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
}
.left {
  margin-right: auto;
  border-radius: 0px 15px 15px 15px;
  & > .messageContainer > .message {
    background: #f8f8f8;
  }
}
.right {
  margin-left: auto;
  border-radius: 15px 0px 15px 15px;
  & > .messageContainer > .message {
    background: #edf0ff;
  }
}
.optionsBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  transition: all 0.5s;
  & > svg {
    fill: #263b53 !important;
  }
  &:hover {
    background: #e2e6f5;
  }
}
.sendBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: none;
  outline: none;
  background: #70a4cb;
  cursor: pointer;
  transition: all 0.5s;
  & > svg {
    fill: white !important;
  }
  &:hover {
    transform: scale(1.1);
    background: #6594b7;
  }
}
.interface {
  position: relative;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  transition: all 0.5s;
  & input {
    width: 100%;
    max-width: 590px;
    height: 20px;
    border: none;
    background: none;
    resize: none;
    outline: none;
    transition: all 0.5s;
    font-size: 16px;
    color: #263b53;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
.form {
  padding: 20px;
  border-radius: 15px;
  background: #f5f6fa;
  display: flex;
  align-items: center;
  gap: 20px;
}
.notFound {
  text-align: center;
  max-width: 500px;
  margin-top: auto;
}

.user > div {
  width: 44px;
  height: 42px;
}

.list {
  position: absolute;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
  max-width: 117px;

  background: #ffffff;
  box-shadow: 2px 4px 10px rgba(91, 101, 114, 0.18);
  border-radius: 8px;
  & > li {
    width: 100%;
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      background: #f5f6fa;
    }
  }
}

.addFile {
  display: none;
}

.addFileLabel {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #263b53;
  cursor: pointer;
}

.previews {
  display: flex;
  gap: 5px;
}
.photo {
  width: 80px;
  height: 60px;
  background: #d9d9d9;
  background-size: cover;
  &:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #f5f6fa;
  margin-left: auto;
  border-radius: 4px;
  cursor: pointer;
}

.messageFiles {
  display: flex;
  gap: 5px;
  & > div {
    margin-bottom: 0;
  }
  & img {
    width: 80px;
    height: 60px;
    object-fit: cover;
    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.previewFile {
  padding: 20px;
  border: 1px solid #bac1ca;
}
