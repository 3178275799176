@import '../../../styles/helpers/variables.scss';

.edit {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  & {
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.top {
  width: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px 10px;
  margin-bottom: 16px;
}

.title {
  display: inline-block;
}

.content {
  flex: 1 0;
  min-width: 1277px;
  padding: 0 24px;
}

.btnGroup {
  display: flex;
  align-items: center;
  gap: 16px;
  & > article {
    width: max-content;
  }
}
