@import '../../../../../../../styles/helpers/variables.scss';

.hotels {
  width: max-content;
  // max-width: 1108px;
  padding-bottom: 80px;
}

// .tableWrapper {
//   overflow: hidden;
// }

.table {
  position: relative;
  width: 100%;
  background: $color-white;
  margin-top: 12px;
  // border-collapse: collapse;
  // overflow: auto;
  // min-height: 220px;
  // max-height: 370px;
}

.column {
  color: $color-black-title;
  height: 40px;
  background-color: $color-table-bg;
  text-align: left;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 12px;

  &_hotel {
    width: 149px;
  }

  &_typeRoom {
    width: 270px;
  }
  &_category {
    width: 270px;
  }
  &_food_type_string {
    width: 305px;
  }
  &_places_count {
    width: 115px;
  }
  &_placements_type {
    width: 145px;
  }
  &_price {
    width: 80px;
  }
  &_checkbox {
    width: 31px;
  }
  &_saled {
    width: 90px;
  }
}

.cell {
  color: $color-black-title;
  padding: 10px 12px;
  font-size: 15px;
  min-height: 56px;
  background-color: $color-table-bg;
  border-radius: 5px;
  //   margin-right: 12px;
  flex-shrink: 0;
}

.headers {
  position: sticky;
  top: 60px;
  z-index: 98;
  width: max-content;
  display: flex;
  background-color: $color-table-bg;
  border-radius: 8px;
}

.row {
  display: flex;
}

.row {
  width: max-content;
  padding: 16px 0;
  border-bottom: 1px solid $color-border-dark-30;
  min-height: 38px;
  display: flex;
  gap: 12px;
}

.hotel {
  width: 161px;
}
.select {
  padding: 0;
}
.select div {
  width: max-content;
  min-width: 100%;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  align-items: center;
}
.select ul {
  width: 100%;
}
.typeRoom {
  width: 270px;
}
.placementType {
  width: 145px;
}
.category {
  width: 270px;
}
.food_type_string {
  width: 300px;
}
.places_count {
  width: 115px;
}
.price {
  width: 80px;
}

.newItem {
  border: none;

  &:active {
    border: none;
  }
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}
.buttonWrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.addRow {
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  padding: 8px 16px;
}
.datePicker {
  width: 161px;
  padding: 0;
}
.datePicker button {
  background: transparent;
  padding: 0;
}
.checkbox {
  margin: auto 0;
}
.saled {
  width: 90px;
}
