@import '../../../styles/helpers/variables.scss';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  margin-bottom: 16px;
  border-radius: 4px;

  &:hover {
    background-color: $color-table-bg;

    & .delete {
      display: block;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

.checkbox {
  width: 19px;
  height: 19px;
  background-color: transparent;
  border-radius: 50%;
  border: 1.5px solid $color-border-dark;
  cursor: pointer;
  appearance: none;
  margin-right: 16px;
  margin-left: 16px;
  flex-shrink: 0;
}

.checkboxChecked {
  background: $color-green;
  border: 1.5px solid $color-green;
  background-image: url('../../../assets/icons/checkedV.svg');
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in;
}

.label {
  color: $color-black-title;
}

.labelChecked {
  text-decoration: line-through;
}

.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.date {
  font-size: 13px;
  color: $color-grey-title-75;
}

.wrapperDelete {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 5px;
}

.delete {
  display: none;
}
