@import '../../../../styles/helpers/variables.scss';

.choice {
  position: absolute;
  width: 356px;
  height: 131px;
  content: '';
  top: 50px;
  left: -20px;
  background: $color-white;
  box-shadow: 0px 4px 8px rgba(91, 101, 114, 0.25);
  border-radius: 8px;
  padding: 16px;
  z-index: 1;
}

.input {
  background: #f5f6fa;
  border-radius: 8px 8px 8px 8px;
  height: 38px;
  padding: 8px 16px;
  color: $color-grey-title;
  font-size: 15px;
  cursor: pointer;
  position: relative;

  &::after {
    position: absolute;
    width: 8px;
    height: 5px;
    content: url('../../../../assets/icons/downContur.svg');
    right: 16px;
  }
}

.inputOpened {
  border-radius: 8px 8px 0px 0px;
}

.search {
  padding: 8px 16px;
  background: $color-white;
  border: none;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-grey-title;
  font-size: 15px;
  width: 100%;
  padding-bottom: 8px;

  &:focus {
    outline: none;
  }
}

.list {
  box-shadow: 0px 4px 8px rgba(91, 101, 114, 0.25);
  border-radius: 0px 0px 8px 8px;
  z-index: 1;
  max-height: 230px;
  overflow-y: auto;
}

.item {
  background: $color-white;
  padding: 8px 16px;
  color: $color-black-title;
  font-size: 15px;
  cursor: pointer;

  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  &:hover {
    background: #f5f6fa;
  }
}

.btnApply {
  margin-top: 16px;
  position: absolute;
  content: '';
  top: 54px;
  left: 16px;
  width: 324px;
  z-index: -1;
}
