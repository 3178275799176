@import '../../../../../../styles/helpers/variables.scss';

.tableWrapper {
  overflow: hidden;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}
.link {
  text-decoration: none;
  color: #5995c4;
}
.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_id {
    width: 65px;
    border-radius: 8px 0px 0px 0px;
  }

  &_title {
    width: 331px;
  }

  &_documentType {
    width: 331px;
  }

  &_date {
    width: 215px;
  }
  &_lk {
    width: 260px;
  }
  &_delete {
    width: 120px;
  }
}

.cell {
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 14px 16px;
  font-size: 16px;
  min-height: 73px;
}
.rows {
  width: 100%;
}
.rows,
.row {
  display: flex;
}

.id {
  width: 65px;
  border-radius: 8px 0px 0px 0px;
}

.title {
  width: 331px;
}

.documentType {
  width: 331px;
}

.date {
  width: 200px;
}

.route {
  min-width: 200px;
  width: 100%;
}
.lk {
  width: 220px;
}
.count {
  padding: 14px 16px;
  color: $color-grey-title-75;
  font-size: 15px;
  line-height: 18px;
  border: none;
  height: 48px;
}

.notFound {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}

.remove {
  width: 140px;
  padding-left: auto;
  padding-right: 0;
  display: flex;
  // align-items: center;
  padding-top: 20px;
  justify-content: center;
  & > span {
    cursor: pointer;
  }
}

.addFile {
  display: none;
}

.addFileLabel {
  display: inline-block;
  margin-top: 24px;
  color: $blue-color;
  background-color: transparent;
  box-shadow: none;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid var(--unnamed, #70a4cb);
  color: var(--unnamed, #70a4cb);
  transition: all 0.2s ease-in;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    color: $blue-color-dark;
    background-color: transparent;
    transition: all 0.2s ease-in;
  }
}

.disabledBtn {
  cursor: auto;
  opacity: 0.7;
}
