.cell {
  position: relative;

  &::after {
    position: absolute;
    width: 11px;
    height: 11px;
    content: '';
    top: 12px;
    right: -20px;
    transform: translateY(-50%);
    background-image: url('../../assets/icons/downConturTable.svg');
    background-repeat: no-repeat;
    transition: all 0.3ms ease;
  }
}

.cellUp {
  &::after {
    background-image: url('../../assets/icons/downConturTableUp.svg');
    transition: all 0.3ms ease;
  }
}
