.document {
  border-radius: 8px;
  background-color: #f5f6fa;
}

.top {
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  cursor: pointer;
  position: relative;

  &::after {
    position: absolute;
    width: 12px;
    height: 7px;
    content: '';
    right: 100px;
    top: 50% -6px;
    background-image: url('../../assets/icons/downContur.svg');
    background-position: 50% 50%;
    background-size: 12px 7px;
  }

  &_opened {
    &::after {
      transform: rotate(180deg);
    }
  }
}

.title {
  font-weight: 600;
  font-size: 15px;
}

.controls {
  display: flex;
}

.remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border: none;
  background: transparent;
  cursor: pointer;

  & svg {
    fill: #5b6572;
  }

  &:hover svg {
    fill: tomato;
  }
}

.content {
  max-width: 744px;
  width: 100%;
  padding: 24px 14px;
}

.notChecked {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  border: none;
  background: transparent;
  background-image: url(../../assets/icons/notChecked.svg);
  background-repeat: no-repeat;
  background-size: 19px 19px;
  background-position: 50% 50%;
  padding: 0;

  cursor: pointer;
}

.checked {
  background-image: url(../../assets/icons/checked.svg);
}
