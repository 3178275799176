@import '../../../../../../styles/helpers/variables.scss';

.tableWrapper {
  overflow: hidden;
}

.table {
  box-shadow: 0px 2px 4px rgba(47, 59, 72, 0.1);
  background: $color-white;
  border: 1px solid rgba(200, 205, 216, 0.3);
  border-radius: 8px 8px 8px 8px;
  margin-top: 12px;
  border-collapse: collapse;
  overflow-x: auto;
}

.column {
  color: $color-blue-font;
  font-weight: 600;
  height: 45px;
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  background-color: #f5f6fa;
  text-align: left;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_id {
    width: 40px;
    border-radius: 8px 0px 0px 0px;
  }

  &_idInPaymentSystem {
    width: 199px;
  }

  &_paymentType {
    width: 199px;
  }

  &_sum {
    width: 119px;
  }

  &_date {
    width: 199px;
  }

  &_valid {
    width: 170px;
  }
  &_code {
    width: 90px;
  }
  &_paymentStatus {
    width: 199px;
  }
  &_buttons {
    width: 280px;
  }
}

.cell {
  border-bottom: 1px solid rgba(200, 205, 216, 0.3);
  color: $color-black-title;
  padding: 14px 16px;
  font-size: 16px;
  min-height: 73px;
}

.rows,
.row {
  display: flex;
}

.id {
  width: 40px;
  border-radius: 8px 0px 0px 0px;
}

.idInPaymentSystem {
  width: 199px;
}

.paymentType {
  width: 199px;
}

.sum {
  width: 119px;
}

.date {
  width: 199px;
}

.valid {
  width: 180px;
}
.code {
  width: 80px;
  padding: 14px 0;
}
.paymentStatus {
  width: 199px;
}

.route {
  min-width: 200px;
  width: 100%;
}

.count {
  padding: 14px 16px;
  color: $color-grey-title-75;
  font-size: 15px;
  line-height: 18px;
  border: none;
  height: 48px;
}

.notFound {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  color: $color-black-title;
}

.buttons {
  display: flex;
  gap: 8px;
  & > button {
    white-space: nowrap;
    height: fit-content;
  }
}

.copyButton {
  background: none;
  box-shadow: none;
  &:hover {
    background: none;
  }
}
