@import '../../helpers/variables.scss';

.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 40%);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1001;

  &_close {
    display: none;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100vh;
  min-width: 808px;
  // overflow-y: scroll;
  // overflow-x: visible;
  &,
  &_reports {
    background: $color-white;
    box-shadow: 0 20px 40px rgb(39 95 158 / 20%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &_reports {
    height: 35.25rem;
    min-width: 24.875rem;
  }
}

.close {
  border-radius: 50%;
  border: 1px solid rgba(200, 205, 216, 0.3);
  background-color: $color-white;
  top: 24px;
  left: -16px;

  &,
  &_reports {
    position: absolute;
    content: '';
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
  }

  &_reports {
    top: 0.375rem;
    right: 0.375rem;
    background-color: transparent;
    border: none;
  }
}

.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  padding-left: 32px;
}
