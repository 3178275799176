@import '../../../styles/helpers/variables.scss';

.top {
  padding: 32px 0 12px;
  width: 100%;
}

.counteragents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.search {
  display: flex;
  gap: 16px;
}

.form {
  padding: 1.375rem 0;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &,
  & > * {
    width: 100%;
  }
}

.heading {
  padding: 0 2rem 1.625rem 2rem;
  border-bottom: 0.125rem solid $color-border-dark-30;
}

.formContent {
  padding: 2rem 2rem 0rem 2rem;
}

.formButton {
  width: calc(100% - 4rem);
  margin-top: 1rem;
  margin-left: 2rem;
  padding: 7px 16px;
}

.datepicker {
  max-width: 100%;
  & > label {
    color: $color-grey-label;
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
    display: block;
    font-weight: 400;
  }
}

.reportType,
.manager,
.operator {
  & > div {
    background: white;
    border: 0.0625rem solid rgba(200, 205, 216, 0.6);
  }
}
.operator > ul {
  height: 180px;
  overflow-y: scroll;
}
.topButton {
  width: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6012rem;
  position: relative;
  padding: 7px 16px;
}

.topButton::before {
  content: '+';
  font-size: 1.125rem;
  font-weight: 400;
}
