@import '../../../../../styles/helpers/variables.scss';

.documents {
  margin-bottom: 24px;
  position: relative;
}

.top {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 24px;
}

.add {
  width: 19px;
  height: 19px;
  background: $blue-color;
  box-shadow: 2.11111px 4.22222px 8.44444px #e5e8f3;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  cursor: pointer;
}

.content {
  & article {
    margin-bottom: 24px;
  }
}

.list {
  position: absolute;
  content: '';
  top: 35px;
  left: 220px;
  width: 195px;
  background-color: $color-white;
  box-shadow: 2px 4px 10px rgba(91, 101, 114, 0.18);
  border-radius: 8px;
  z-index: 10;
}

.item {
  color: $color-black-title;
  padding: 8px 16px;
  font-size: 15px;
  cursor: pointer;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background: #f5f6fa;
    color: $blue-color-dark;
  }
}
