@import '../../styles/helpers/variables.scss';

.buttons {
  //   margin-left: -24px;
  //   margin-right: -24px;
  //   padding: 12px 24px;
  border-top: 1px solid rgba(200, 205, 216, 0.3);
  height: 62px;
  width: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.save,
.cancel {
  height: 38px;
}

.save {
  width: 146px;
}

.cancel {
  width: 117px;
  background-color: transparent;
  color: $color-grey-title;
  border-radius: 8px;
  box-shadow: none;

  &:hover {
    background: #f5f6fa;
  }
}
