@import '../../../../../../styles/helpers/variables.scss';

.button {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 20px;

  & svg {
    fill: $color-grey-normal;
  }

  &:active {
    transform: translateY(1px);
  }
}

.buttonBack {
  & svg {
    transform: rotate(180deg);
  }
}
