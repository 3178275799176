@import '../../../styles/helpers/variables.scss';

.top {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.topUp {
  width: 8px;
  height: 6px;
  background-image: url(../../../assets/icons/downContur.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding-left: 20px;
  transition: all 0.2s ease-in;
  margin-left: 10px;
  margin-right: 10px;
}

.topDown {
  transform: rotate(180deg);
  transition: all 0.2s ease-in;
}

.line {
  width: 100%;
  height: 2px;
  background-color: $color-border-dark-30;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
  padding: 16px 0;
}

.btn {
  border: 1px solid $color-border-dark;
  border-radius: 3px;
  color: $color-grey-title-75;
  background-color: transparent;

  &:hover {
    border: 1px solid $color-border-dark-30;
    background-color: transparent;
    transition: all 0.2s ease-in;
  }
}

.btnActive {
  background-color: $color-orange;
  border: 1px solid $color-orange;
  color: $color-white;

  &:hover {
    background-color: $color-orange-hover;
    transition: all 0.2s ease-in;
  }
}

.btnSettings {
  height: 30px;
  background-color: transparent;
  border: none;
  color: $color-grey-title-75;
  box-shadow: none;
  border-bottom: 1px dashed $color-grey-title-75;
  padding: 0;
  border-radius: 0;
  margin-left: auto;
  margin-right: 0;

  &:hover {
    color: $color-grey-title;
    background-color: transparent;
  }
}
